import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { useNavigate } from 'react-router-dom';
import "./IdConnect.css";
import backArrowImg from '../../img/right-arrow.png'


const IdConnect = ({loginPage}) => {
  const navigate = useNavigate();
  const [elevatorId, setElevatorId] = React.useState("");

  return (
      <div id="id-connect" className={loginPage ? "login-id-connect" : "sidebar-id-connect"}>
          {loginPage && <h1>Connect to an elevator</h1>}
          <div id="elevator-id-container">
            <div id="elevatator-id-info">
              {loginPage
                ? <label id="elevator-id-label"  htmlFor="elevator-id">Elevator ID</label>
                : <img src={require("../../img/picto-elevator.png")} alt='elevator' />
              }
            </div>
            <input
              type="text"
              id="elevator-id"
              placeholder={loginPage ? "Insert ID" : "Elevator ID"}
              value={elevatorId} onChange={(e) => setElevatorId(e.target.value)} />
            <button
              title="Connect to elevator"
              id="connect-button"
              type="button"
              onClick={() => {if (elevatorId.length > 0) navigate(`/chatroom/${elevatorId}`)}}
            >
              <img src={backArrowImg} alt='Back button' />
            </button>
          </div>
      </div>
  );
};

export default IdConnect;
