import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import "./css/ProductMenuItem.css";
import ProductFormProvider from "./ProductFormProvider";
import Modal from "../Modal/Modal";
import Action from "../Action/Action";
import PopupProductsModelFileInfo from "./PopupProductsModelFileInfo";
import LinkVisual from "./LinkVisual";
import { API, graphqlOperation } from "aws-amplify";
import { getSIPAccountStormByDeviceIDCustom } from "../../graphql/queries";
import { StatusCodes } from "../../constants/StatusCodes";
import pictoInfoSheet from "../../img/picto-info-sheet.svg";
import EditVoip from "./EditVoip";
import { FormProvider } from "../Form/FormProvider";
import { InputGlobal } from "../Form/InputGlobal";
import Callees from "../../containers/Callees/Callees";

const ProductsMenuItem = (props) => {
  const item = props.item;
  const [device, setDevice] = useState(item);
  const [initialSIPConfig, setInitialSIPConfig] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [informationSheetModalOpen, setInformationSheetModalOpen] =
    useState(false);
  const [connectVoipLineModalOpen, setConnectVoipLineModalOpen] =
    useState(false);
  const [linkVisualModalOpen, setLinkVisualModalOpen] = useState(false);
  const [calleesModalOpen, setCalleesModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    async function getSIPAccount() {
      try {
        const values = await API.graphql(
          graphqlOperation(getSIPAccountStormByDeviceIDCustom, {
            device_id: item.id,
          })
        );
        if (
          values.data.getSIPAccountStormByDeviceID?.statusCode ===
          StatusCodes.OK
        ) {
          const SIPAccount = JSON.parse(
            values.data.getSIPAccountStormByDeviceID?.body
          );
          setDevice((previousDevice) => ({
            ...previousDevice,
            ...item,
            CustomSIPAccount: SIPAccount.CustomSIPAccount,
            VidatechSIPAccount: SIPAccount.VidatechSIPAccount,
            CustomSIPForm: SIPAccount.CustomSIPAccount ? true : false,
          }));
          setInitialSIPConfig({
            DeviceID: item.id,
            ...SIPAccount,
            CustomSIPAccount: {
              ...SIPAccount.CustomSIPAccount,
              PbxExtension: parseInt(SIPAccount.CustomSIPAccount?.PbxExtension),
            },
          });
        } else {
          setInitialSIPConfig({
            VidatechSIPAccount: false,
            DeviceID: item.id,
          });
        }
      } catch (error) {
        console.error("Error fetching SIP account");
      }
    }
    if (props.isAuthorized) {
      getSIPAccount();
    }
  }, [item]);

  const handleDelete = () => {
    props.onDelete(item.id);
  };

  const handleUpdate = async (data) => {
    props.onUpdate(data);
  };

  const actionOptions = [
    {
      text: "Information sheet",
      img: pictoInfoSheet,
      onClickOption: () => setInformationSheetModalOpen(true),
    }
  ];

  if(!props.isPhone) {
    actionOptions.push({
      text: "Link visual",
      img: require("../../img/picto-theme-17.png"),
      onClickOption: () => setLinkVisualModalOpen(true),
    });
  }

  if (props.isAuthorized) {
    if(!props.isPhone) {
      actionOptions.push({
          text: "Edit Callees",
          img: require("../../img/picto-edit-1.png"),
          onClickOption: () => setCalleesModalOpen(true),
          //onClickOption: () => navigate(`/buildings/${buildingId}/products/${item.id}/callees`),
      });
    }
    actionOptions.push(
      {
        text: "Connect VOIP line",
        img: require("../../img/groupe-750-1x-png.png"),
        onClickOption: () => setConnectVoipLineModalOpen(true),
      },
      {
        text: "Edit",
        img: require("../../img/picto-edit-1.png"),
        onClickOption: () => setEditModalOpen(true),
      },
      {
        text: "Delete",
        img: require("../../img/picto-delete.png"),
        onClickOption: () => setDeleteModalOpen(true),
      }
    );
  }

  const isEqualToInitialValue = (data) => {
    let stringifiedInitialSIPConfig = JSON.stringify(initialSIPConfig);
    let stringifiedData = JSON.stringify(data);
    let isEqual = stringifiedInitialSIPConfig === stringifiedData;
    setConnectVoipLineModalOpen(!isEqual);
    return isEqual;
  };

  const handleCheckboxChange = () => {
    props.onCheckboxChange(item.id);
  }

  return (
    <>
      <tr id={item.id} key={props.key}>
        <td className="cell-container">
          <div className="table-cell checkbox-cell">
            <FormProvider initialValues={{ isSelected: props.isSelected }}>
              <InputGlobal
                className={props.isSelected ? "checked" : ""}
                name={"isSelected"}
                type="checkbox"
                checked={props.isSelected}
                onChange={handleCheckboxChange}
              />
            </FormProvider>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className="table-cell">{item.PhysicalAddress}</div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className="table-cell">{item.Name}</div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className="table-cell">{item.Models}</div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className="table-cell">
              {item.ThemeFolderName.replace(/_R_(\d+x\d+)$/, "")}
            </div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className="table-cell">
              {item.Status === null ? (
                <div className="status-icon" />
              ) : item.Status === 1 ? (
                <div className="status-icon green-status" />
              ) : (
                <div className="status-icon red-status" />
              )}
            </div>
            <span className="separator"></span>
          </div>
        </td>
        <td>
          <Action actionOptions={actionOptions} />
          <div className="information-sheet-modal">
            <Modal
              headerText={`INFORMATION OF THE PRODUCT - ${item.Name} - ${item.Models}`}
              isOpen={informationSheetModalOpen}
              onClose={() => setInformationSheetModalOpen(false)}
            >
              <PopupProductsModelFileInfo item={device} />
            </Modal>
          </div>
          <div
            className={
              "voip-line-modal" +
              (device.CustomSIPForm ? " custom-voip-line-modal" : "")
            }
          >
            {connectVoipLineModalOpen &&
                <FormProvider>
                  <EditVoip onClose={() => setConnectVoipLineModalOpen(false)} deviceID={item.id} />
                </FormProvider>
            }
          </div>
          <div className="link-visual-modal">
            <Modal
              headerText={`LINK ${item.Name}'S SCREEN TO A VISUAL`}
              isOpen={linkVisualModalOpen}
              onClose={() => setLinkVisualModalOpen(false)}
            >
              <LinkVisual item={device} onLinkVisual={props.onLinkVisual} />
            </Modal>
          </div>
          { calleesModalOpen &&
            <Callees
              headerText={`CALLEES OF ${item.Name}`}
              onClose={() => setCalleesModalOpen(false)}
              calleesModalOpen={calleesModalOpen}
              deviceId={item.id}
            />
          }
          <div className="confirm-modal">
            <Modal
              isOpen={deleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              buttonText="Delete"
              buttonClick={handleDelete}
            >
              <>
                <img
                  src={require("../../img/icon-material-warning@1x.png")}
                  alt="confirm delete"
                />
                <p>Do you really want to remove this product?</p>
              </>
            </Modal>
          </div>
          <div className="form-product-modal">
            <Modal
              headerText={`EDIT PRODUCT - ${item.Name}`}
              isOpen={editModalOpen}
              onClose={() => setEditModalOpen(false)}
            >
              <ProductFormProvider
                productModels={props.productModels}
                item={device}
                devices={props.devices}
                onSubmit={handleUpdate}
              />
            </Modal>
          </div>
        </td>
      </tr>
    </>
  );
};

export default withTranslation()(ProductsMenuItem);
