import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
  listDevicesPageByBuildingsID,
  listModelsName,
} from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import {
  deleteDeviceStormCustom,
  createDeviceWithDeviceInfo,
  updateDeviceWithDeviceInfoRuntime,
  manageSipAccountStormCustom,
  linkVisualStorm,
} from "../../graphql/mutations";
import ProductsMenuItem from "../../components/products/ProductsMenuItem";
import Loader from "../../constants/Loader";
import { useParams } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import ProductFormProvider from "../../components/products/ProductFormProvider";
import Layout from "../Layout/Layout";
import ItemsTable from "../../components/Table/ItemsTable";
import { PRODUCT_HEADERS } from "../../constants/TableHeaders";
import { StatusCodes } from "../../constants/StatusCodes";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { COGNITO_GROUPS } from "../../constants/DefaultValues";
import CreateButton from "../../components/Buttons/CreateButton";
import arrowDownImage from "../../img/arrow-down.png";
import "./css/product.css";
import LinkVisual from "../../components/products/LinkVisual";

const ProductsPage = () => {
  const { buildingId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [linkVisualModalOpen, setLinkVisualModalOpen] = useState(false);
  const [productModels, setProductModels] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [createProductModalOpen, setCreateProductModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("An error occured");
  const [phoneModelsIds, setPhoneModelsIds] = useState([]);
  const { user } = useAuthenticator((context) => [context.user]);
  const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
  const authorizedGroups = [
    COGNITO_GROUPS.VIDATECH_ADMIN,
    COGNITO_GROUPS.ADMIN,
    COGNITO_GROUPS.COLLABORATOR,
  ];
  const itemsPerPage = 10;
  const isAuthorized = groups?.some((group) =>
    authorizedGroups.includes(group)
  );

  const handleCreate = async (products) => {
    try {
      setIsLoading(true);
      setCreateProductModalOpen(false);
      products.buildingsID = buildingId;

      const result = await API.graphql(
        graphqlOperation(createDeviceWithDeviceInfo, { input: products })
      );
      if (
        result.data.createDeviceWithDeviceInfoRuntime.statusCode !==
        StatusCodes.OK
      ) {
        throw new Error("Error creating product");
      }

      await getDevices();
    } catch (error) {
      console.log(error);
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (product) => {
    try {
      setIsLoading(true);
      const result = await API.graphql(
        graphqlOperation(updateDeviceWithDeviceInfoRuntime, { input: product })
      );
      if (
        result.data.updateDeviceWithDeviceInfoRuntime.statusCode !==
        StatusCodes.OK
      ) {
        throw new Error("An error occured while updating the product");
      }
      await getDevices();
    } catch (error) {
      console.error("An error occured while updating the product.", error);
      if (error.message) {
        setErrorMessage(error.message);
      } else if (error.errors) {
        setErrorMessage(error.errors[0].message);
      }
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateSIPAccount = async (data) => {
    try {
      setIsLoading(true);
      const res = await API.graphql(
        graphqlOperation(manageSipAccountStormCustom, { input: data })
      );
      if (res.data.manageSipAccountStorm.statusCode !== StatusCodes.OK) {
        throw new Error(res.data.manageSipAccountStorm.body);
      }
    } catch (error) {
      console.error("Error updating SIP Account:", error);
      if (error.message) {
        setErrorMessage(error.message);
      } else if (error.errors) {
        setErrorMessage(error.errors[0].message);
      } else {
        setErrorMessage("An error occured while updating the SIP Account");
      }
      setErrorModalOpen(true);
    } finally {
      await getDevices();
      setIsLoading(false);
    }
  };

  async function getDevices() {
    setIsLoading(true);
    try {
      const values = await API.graphql(
        graphqlOperation(listDevicesPageByBuildingsID, {
          buildingsID: buildingId,
        })
      );

      const sortedDevices = values.data.devicesByBuildingsID.items.sort(
        (a, b) => a.PhysicalAddress - b.PhysicalAddress
      );
      const deviceModels = sortedDevices.map((device) => {
        return {
          id: device.id,
          PhysicalAddress: device.PhysicalAddress,
          Name: device.Name,
          Models: `${device.Models.Name} ${
            device.Models.Description
              ? "(" + device.Models.Description + ")"
              : ""
          }`,
          ThemeFolderName: device.ThemeFolderName || "",
          DoorNumber: device.DoorNumber,
          DeviceInfoRuntime: device.DeviceInfoRuntime,
          ModelsResolution: device.Models.Resolution,
          modelsID: device.modelsID,
        };
      });

      setDevices(deviceModels);
    } catch (error) {
      setErrorModalOpen(true);
      setErrorMessage("An error occured while getting the products");
    } finally {
      setIsLoading(false);
    }
  }

  async function getProductModels() {
    try {
      const values = await API.graphql(graphqlOperation(listModelsName));
      let models = values.data.listModels.items;
      //Remove the Gateway model from the list
      models = models.filter((model) => !/^TG/.test(model.Name));

      setProductModels(models);
      setPhoneModelsIds(models.filter(model => /^TP/.test(model.Name)).map((model) => model.id));
    } catch (error) {
      setErrorModalOpen(true);
      setErrorMessage("An error occured while getting the models");
    }
  }

  useEffect(() => {
    getDevices();
    getProductModels();
  }, [buildingId]);

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      await API.graphql(
        graphqlOperation(deleteDeviceStormCustom, { input: { id: id } })
      );
      getDevices();
    } catch (error) {
      console.error("Error deleting product:", error);
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  async function linkVisual(visualName, deviceId) {
    const result = await API.graphql(
      graphqlOperation(linkVisualStorm, {
        input: {
          folderName: visualName,
          deviceID: deviceId,
        },
      })
    );
    if (result.data.linkVisualStorm.statusCode !== StatusCodes.OK) {
      if (result.data.linkVisualStorm.body) {
        throw new Error(
          "Error linking visual. " + result.data.linkVisualStorm.body
        );
      } else {
        throw new Error("Error linking visual");
      }
    }
  }

  async function handleLinkVisual(visualName, deviceId) {
    try {
      setIsLoading(true);
      await linkVisual(visualName, deviceId);
    } catch (e) {
      if (e.message) {
        setErrorMessage(e.message);
      }
      setErrorModalOpen(true);
    } finally {
      await getDevices();
      setIsLoading(false);
    }
  }

  async function handleLinkVisualToMultipleDevices(visualName) {
    setIsLoading(true);
    setLinkVisualModalOpen(false);

    setSelectedDevices([]);
    setAllSelected(false);

    try {
      const promises = selectedDevices.map((device) => linkVisual(visualName, device.id));
      await Promise.all(promises);
    } catch (e) {
      if (e.message) {
        setErrorMessage(e.message);
      }
      setErrorModalOpen(true);
    } finally {
      await getDevices();
      setIsLoading(false);
    }
  }

  const handleSortBy = (header, sortOrder) => {
    const sortedItems = [...devices];
    if (header === "ID" || header === "Status") {
      if (sortOrder === "asc") {
        sortedItems.sort(
          (a, b) => a[PRODUCT_HEADERS[header]] - b[PRODUCT_HEADERS[header]]
        );
        setDevices(sortedItems);
      } else {
        sortedItems.sort(
          (a, b) => b[PRODUCT_HEADERS[header]] - a[PRODUCT_HEADERS[header]]
        );
        setDevices(sortedItems);
      }
    } else {
      if (sortOrder === "asc") {
        sortedItems.sort((a, b) =>
          a[PRODUCT_HEADERS[header]].localeCompare(
            b[PRODUCT_HEADERS[header]],
            undefined,
            { numeric: true, sensitivity: "base" }
          )
        );
        setDevices(sortedItems);
      } else {
        sortedItems.sort((a, b) =>
          b[PRODUCT_HEADERS[header]].localeCompare(
            a[PRODUCT_HEADERS[header]],
            undefined,
            { numeric: true, sensitivity: "base" }
          )
        );
        setDevices(sortedItems);
      }
    }
  };

  const handleSelectAll = (selectedDevicesIds) => {
    if (!allSelected && selectedDevicesIds.length > 0) {
      setSelectedDevices(
        devices.filter((device) => selectedDevicesIds.includes(device.id))
      );
      setAllSelected(true);
    } else {
      setSelectedDevices([]);
      setAllSelected(false);
    }
  };

  const handleProductSelected = (productId) => {
    let itemsSelected = [];
    if (selectedDevices.some((device) => device.id === productId)) {
      itemsSelected = selectedDevices.filter(
        (device) => device.id !== productId
      );
      setAllSelected(false);
    } else {
      itemsSelected = [
        ...selectedDevices,
        devices.find((device) => device.id === productId),
      ];
    }
    if (
      itemsSelected.length === itemsPerPage ||
      itemsSelected.length === devices.length
    ) {
      setAllSelected(true);
    }
    setSelectedDevices(itemsSelected);
  };

  const handleCreateButtonClicked = (option) => {
    if (option === "link-visual") {
      setLinkVisualModalOpen(true);
    }
  };

  return (
    <Layout
      headerText="PRODUCTS"
      showSidebar={true}
      showFooter={false}
      headerButtonDisabled={isLoading}
      headerButtonText={isAuthorized && "Create a product"}
      dropdownButton={
        <CreateButton
          text="More options"
          style={selectedDevices.length === 0 ? { visibility: "hidden" } : null}
          className="selected-product-dropdown"
          buttonImgSrc={arrowDownImage}
          onClick={handleCreateButtonClicked}
          options={[
            {
              value: "link-visual",
              label: "Link visual",
            },
          ]}
        />
      }
      onHeaderButtonClick={
        isAuthorized ? () => setCreateProductModalOpen(true) : null
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <ItemsTable
          headers={PRODUCT_HEADERS}
          itemsPerPage={itemsPerPage}
          onSelectAll={handleSelectAll}
          allSelected={allSelected}
          onSortBy={handleSortBy}
        >
          {devices &&
            devices.map((item) => (
              <ProductsMenuItem
                key={item.id}
                isSelected={selectedDevices.some(
                  (device) => device.id === item.id
                )}
                onCheckboxChange={handleProductSelected}
                item={item}
                onDelete={handleDelete}
                productModels={productModels}
                onUpdate={handleUpdate}
                onLinkVisual={handleLinkVisual}
                onUpdateSIPAccount={handleUpdateSIPAccount}
                isPhone={phoneModelsIds.includes(item.modelsID)}
                isAuthorized={isAuthorized}
                devices={devices}
              />
            ))}
        </ItemsTable>
      )}
      <div className="link-visual-modal">
        <Modal
          headerText={`LINK ${selectedDevices.length} DEVICES SCREEN TO A VISUAL`}
          isOpen={linkVisualModalOpen}
          onClose={() => setLinkVisualModalOpen(false)}
        >
          <LinkVisual
            items={selectedDevices}
            onLinkVisual={handleLinkVisualToMultipleDevices}
            />
        </Modal>
      </div>
      <div className="form-product-modal">
        <Modal
          isOpen={createProductModalOpen}
          headerText="CREATE A PRODUCT"
          onClose={() => setCreateProductModalOpen(false)}
        >
          <ProductFormProvider
            productModels={productModels}
            devices={devices}
            onSubmit={handleCreate}
          />
        </Modal>
      </div>
      <div className="error-modal">
        <Modal isOpen={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
          <>
            <img
              src={require("../../img/icon-material-warning@1x.png")}
              alt="warning sign"
            />
            <p>{errorMessage}</p>
          </>
        </Modal>
      </div>
    </Layout>
  );
};
export default withTranslation()(ProductsPage);
