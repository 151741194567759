/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const manageSipAccountStormCustom = /* GraphQL */ `
  mutation ManageSipAccountStorm(
    $input: ManageSIPAccountStormInput!
    $condition: ManageSIPAccountStormConditionInput
  ) {
    manageSipAccountStorm(input: $input, condition: $condition) {
      body
      statusCode
    }
  }
`;

export const createContractorFirstResponderCustom = /* GraphQL */ `
  mutation CreateContractorFirstResponder(
    $input: CreateContractorFirstResponderInput!
    $condition: ModelContractorFirstResponderConditionInput
  ) {
    createContractorFirstResponder(input: $input, condition: $condition) {
      body
      statusCode
    }
  }
`;

export const updateContractorFirstResponderCustom = /* GraphQL */ `
  mutation UpdateContractorFirstResponder(
    $input: UpdateContractorFirstResponderStormInput!
    $condition: ModelContractorFirstResponderStormConditionInput
  ) {
    updateContractorFirstRespondersStorm(input: $input, condition: $condition) {
      body
      statusCode
    }
  }
`;

export const deleteContractorFirstResponderCustom = /* GraphQL */ `
  mutation DeleteContractorFirstResponder(
    $input: DeleteContractorFirstResponderInput!
    $condition: ModelContractorFirstResponderStormConditionInput
  ) {
    deleteContractorFirstRespondersStorm(input: $input, condition: $condition) {
      body
      statusCode
    }
  }
`;
export const createContractorWithAddressCustom = /* GraphQL */ `
  mutation CreateContractorWithAddress(
    $input: ContractorWithAddressInput!
    $condition: ModelContractorWithAddressInput
  ) {
    createContractorWithAddress(input: $input, condition: $condition) {
      body
      statusCode
    }
  }
`;
export const updateBuildingWithAddressCustom = /* GraphQL */ `
  mutation UpdateBuildingWithAddress(
    $input: BuildingsWithAddressInput!
    $condition: ModelBuildingsWithAddressInput
  ) {
    updateBuildingWithAddress(input: $input, condition: $condition) {
      body
      statusCode
    }
  }
`;
export const createDeviceWithDeviceInfo = /* GraphQL */ `
  mutation createDeviceWithDeviceInfoRuntime(
    $input: CreateDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    createDeviceWithDeviceInfoRuntime(input: $input, condition: $condition) {
      body
      statusCode
    }
  }
`;
export const deleteDeviceStormCustom = /* GraphQL */ `
  mutation DeleteDeviceStorm(
    $input: DeleteDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    deleteDeviceStorm(input: $input, condition: $condition) {
      body
      statusCode
    }
  }
`;
export const createBuildingsWithAddress = /* GraphQL */ `
  mutation createBuildingWithAddress(
    $input: BuildingsWithAddressInput!
    $condition: ModelBuildingsWithAddressInput
  ) {
    createBuildingWithAddress(input: $input, condition: $condition) {
      body
      statusCode
    }
  }
`;
export const deleteBuildingWithAddressStormCustom = /* GraphQL */ `
  mutation DeleteBuildingWithAddressStorm(
    $input: DeleteBuildingsInput!
    $condition: ModelBuildingsConditionInput
  ) {
    deleteBuildingWithAddressStorm(input: $input, condition: $condition) {
      body
      statusCode
    }
  }
`;
export const decryptMikrotikPassword = /* GraphQL */ `
  mutation DecryptMikrotikPassword($Password: String!) {
    decryptMikrotikPassword(Password: $Password) {
      statusCode
      body
      __typename
    }
  }
`;
export const setupMikrotik = /* GraphQL */ `
  mutation SetupMikrotik($input: SetupMikrotikInput!) {
    setupMikrotik(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const createTeamsAutomationStorm = /* GraphQL */ `
  mutation CreateTeamsAutomationStorm($id: String!) {
    createTeamsAutomationStorm(id: $id) {
      statusCode
      body
      __typename
    }
  }
`;
export const forceUpdateStorm = /* GraphQL */ `
  mutation ForceUpdateStorm($input: ForceUpdateStorm!) {
    forceUpdateStorm(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const transferBuildingOwnershipStorm = /* GraphQL */ `
  mutation TransferBuildingOwnershipStorm(
    $input: TransferBuildingOwnershipStorm!
  ) {
    transferBuildingOwnershipStorm(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const takeContractorStorm = /* GraphQL */ `
  mutation TakeContractorStorm($input: TakeContractorStormInput!) {
    takeContractorStorm(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const sendContractorInvitation = /* GraphQL */ `
  mutation SendContractorInvitation($input: SendContractorInvitationInput!) {
    sendContractorInvitation(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const acceptUserInvitation = /* GraphQL */ `
  mutation AcceptUserInvitation($token: String!) {
    acceptUserInvitation(token: $token) {
      statusCode
      body
      __typename
    }
  }
`;
export const sendUserInvitation = /* GraphQL */ `
  mutation SendUserInvitation($input: SendUserInvitationInput!) {
    sendUserInvitation(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const updateUserCognito = /* GraphQL */ `
  mutation UpdateUserCognito($input: SendUserInvitationInput!) {
    updateUserCognito(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteUserCognito = /* GraphQL */ `
  mutation DeleteUserCognito($email: String!) {
    deleteUserCognito(email: $email) {
      statusCode
      body
      __typename
    }
  }
`;
export const createThemeStorm = /* GraphQL */ `
  mutation CreateThemeStorm(
    $input: CreateThemeStormInput!
    $condition: CreateThemeStormConditionInput
  ) {
    createThemeStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const addImage = /* GraphQL */ `
  mutation AddImage(
    $input: S3ManagementImageInput!
    $condition: S3ManagementImageConditionInput
  ) {
    addImage(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: S3ManagementImageInput!
    $condition: S3ManagementImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const addFolder = /* GraphQL */ `
  mutation AddFolder(
    $input: S3ManagementFolderInput!
    $condition: S3ManagementFolderConditionInput
  ) {
    addFolder(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteFolder = /* GraphQL */ `
  mutation DeleteFolder(
    $input: S3ManagementFolderInput!
    $condition: S3ManagementFolderConditionInput
  ) {
    deleteFolder(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteDeviceStorm = /* GraphQL */ `
  mutation DeleteDeviceStorm(
    $input: DeleteDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    deleteDeviceStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const createDeviceWithDeviceInfoRuntime = /* GraphQL */ `
  mutation CreateDeviceWithDeviceInfoRuntime(
    $input: CreateDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    createDeviceWithDeviceInfoRuntime(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const updateDeviceWithDeviceInfoRuntime = /* GraphQL */ `
  mutation UpdateDeviceWithDeviceInfoRuntime(
    $input: UpdateDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    updateDeviceWithDeviceInfoRuntime(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const createContractorWithAddress = /* GraphQL */ `
  mutation CreateContractorWithAddress(
    $input: ContractorWithAddressInput!
    $condition: ModelContractorWithAddressInput
  ) {
    createContractorWithAddress(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const updateContractorWithAddress = /* GraphQL */ `
  mutation UpdateContractorWithAddress(
    $input: ContractorWithAddressInput!
    $condition: ModelContractorWithAddressInput
  ) {
    updateContractorWithAddress(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const createBuildingWithAddress = /* GraphQL */ `
  mutation CreateBuildingWithAddress(
    $input: BuildingsWithAddressInput!
    $condition: ModelBuildingsWithAddressInput
  ) {
    createBuildingWithAddress(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const updateBuildingWithAddress = /* GraphQL */ `
  mutation UpdateBuildingWithAddress(
    $input: BuildingsWithAddressInput!
    $condition: ModelBuildingsWithAddressInput
  ) {
    updateBuildingWithAddress(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteBuildingWithAddressStorm = /* GraphQL */ `
  mutation DeleteBuildingWithAddressStorm(
    $input: DeleteBuildingsInput!
    $condition: ModelBuildingsConditionInput
  ) {
    deleteBuildingWithAddressStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const manageSipAccountStorm = /* GraphQL */ `
  mutation ManageSipAccountStorm(
    $input: ManageSIPAccountStormInput!
    $condition: ManageSIPAccountStormConditionInput
  ) {
    manageSipAccountStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const manageCalleesStorm = /* GraphQL */ `
  mutation ManageCalleesStorm(
    $input: ManageRingGroupInput!
    $condition: ModelManageRingGroupInputCondition
  ) {
    manageCalleesStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const createContractorFirstRespondersStorm = /* GraphQL */ `
  mutation CreateContractorFirstRespondersStorm(
    $input: CreateContractorFirstResponderStormInput!
    $condition: ModelContractorFirstResponderStormConditionInput
  ) {
    createContractorFirstRespondersStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const updateContractorFirstRespondersStorm = /* GraphQL */ `
  mutation UpdateContractorFirstRespondersStorm(
    $input: UpdateContractorFirstResponderStormInput!
    $condition: ModelContractorFirstResponderStormConditionInput
  ) {
    updateContractorFirstRespondersStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteContractorFirstRespondersStorm = /* GraphQL */ `
  mutation DeleteContractorFirstRespondersStorm(
    $input: DeleteContractorFirstResponderInput!
    $condition: ModelContractorFirstResponderStormConditionInput
  ) {
    deleteContractorFirstRespondersStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const saveVisualStorm = /* GraphQL */ `
  mutation SaveVisualStorm(
    $input: VisualManagementInput!
    $condition: VisualManagementConditionInput
  ) {
    saveVisualStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const publishVisualStorm = /* GraphQL */ `
  mutation PublishVisualStorm(
    $input: VisualManagementInput!
    $condition: VisualManagementConditionInput
  ) {
    publishVisualStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const createVisualStorm = /* GraphQL */ `
  mutation CreateVisualStorm(
    $input: CreateVisualInput!
    $condition: CreateVisualConditionInput
  ) {
    createVisualStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteVisualStorm = /* GraphQL */ `
  mutation DeleteVisualStorm(
    $input: S3ManagementFolderInput!
    $condition: S3ManagementFolderConditionInput
  ) {
    deleteVisualStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const linkVisualStorm = /* GraphQL */ `
  mutation LinkVisualStorm(
    $input: VisualDeviceInput!
    $condition: VisualDeviceConditionInput
  ) {
    linkVisualStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const restoreVisualStorm = /* GraphQL */ `
  mutation RestoreVisualStorm(
    $input: RestoreVisualInput!
    $condition: RestoreVisualConditionInput
  ) {
    restoreVisualStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const saveLogFileEmbeddedStorm = /* GraphQL */ `
  mutation SaveLogFileEmbeddedStorm(
    $input: SaveLogFileEmbeddedStormInput!
    $condition: SaveLogFileEmbeddedStormConditionInput
  ) {
    saveLogFileEmbeddedStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      Name
      Prefix
      CustomerProjects {
        items {
          id
          Name
          ChannelID
          SaleOrderID
          CustomerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      Name
      Prefix
      CustomerProjects {
        items {
          id
          Name
          ChannelID
          SaleOrderID
          CustomerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      Name
      Prefix
      CustomerProjects {
        items {
          id
          Name
          ChannelID
          SaleOrderID
          CustomerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCustomerProjects = /* GraphQL */ `
  mutation CreateCustomerProjects(
    $input: CreateCustomerProjectsInput!
    $condition: ModelCustomerProjectsConditionInput
  ) {
    createCustomerProjects(input: $input, condition: $condition) {
      id
      Name
      ChannelID
      SaleOrderID
      CustomerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCustomerProjects = /* GraphQL */ `
  mutation UpdateCustomerProjects(
    $input: UpdateCustomerProjectsInput!
    $condition: ModelCustomerProjectsConditionInput
  ) {
    updateCustomerProjects(input: $input, condition: $condition) {
      id
      Name
      ChannelID
      SaleOrderID
      CustomerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCustomerProjects = /* GraphQL */ `
  mutation DeleteCustomerProjects(
    $input: DeleteCustomerProjectsInput!
    $condition: ModelCustomerProjectsConditionInput
  ) {
    deleteCustomerProjects(input: $input, condition: $condition) {
      id
      Name
      ChannelID
      SaleOrderID
      CustomerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGstreamer = /* GraphQL */ `
  mutation CreateGstreamer(
    $input: CreateGstreamerInput!
    $condition: ModelGstreamerConditionInput
  ) {
    createGstreamer(input: $input, condition: $condition) {
      TaskID
      InUse
      Status
      StreamName
      Device {
        id
        Name
        PhysicalAddress
        DoorNumber
        VciConfigName
        ViaConfigName
        DeviceInfoRuntime {
          id
          ErrorsCode
          Status
          PowerCycles
          IpAddress
          MacAddress
          FirmwareVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SIPAccount {
          items {
            id
            Name
            Type
            SIPOwner
            PrimaryAddress
            SecondaryAddress
            PbxUsername
            PbxPassword
            PbxExtension
            ClientPeerId
            StreamName
            PhoneNumber
            DeviceID
            Port
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        modelsID
        buildingsID
        Models {
          id
          Name
          Description
          Resolution
          IsStreaming
          IsSipPhone
          IsSipPublic
          IsSipPrivate
          MaxMemorySizeInKB
          DeviceType
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamIpAddress
        RingGroup {
          id
          Name
          Extension
          Callees {
            items {
              id
              Number
              LinkNumber
              LinkEmail
              Priority
              SIPAccount {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              RingGroupID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              calleesSIPAccountId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Time
          CountryCode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamPort
        GroupID
        ThemeFolderName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        devicesDeviceInfoRuntimeId
        devicesRingGroupId
        __typename
      }
      Start
      End
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      gstreamerDeviceId
      __typename
    }
  }
`;
export const updateGstreamer = /* GraphQL */ `
  mutation UpdateGstreamer(
    $input: UpdateGstreamerInput!
    $condition: ModelGstreamerConditionInput
  ) {
    updateGstreamer(input: $input, condition: $condition) {
      TaskID
      InUse
      Status
      StreamName
      Device {
        id
        Name
        PhysicalAddress
        DoorNumber
        VciConfigName
        ViaConfigName
        DeviceInfoRuntime {
          id
          ErrorsCode
          Status
          PowerCycles
          IpAddress
          MacAddress
          FirmwareVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SIPAccount {
          items {
            id
            Name
            Type
            SIPOwner
            PrimaryAddress
            SecondaryAddress
            PbxUsername
            PbxPassword
            PbxExtension
            ClientPeerId
            StreamName
            PhoneNumber
            DeviceID
            Port
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        modelsID
        buildingsID
        Models {
          id
          Name
          Description
          Resolution
          IsStreaming
          IsSipPhone
          IsSipPublic
          IsSipPrivate
          MaxMemorySizeInKB
          DeviceType
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamIpAddress
        RingGroup {
          id
          Name
          Extension
          Callees {
            items {
              id
              Number
              LinkNumber
              LinkEmail
              Priority
              SIPAccount {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              RingGroupID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              calleesSIPAccountId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Time
          CountryCode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamPort
        GroupID
        ThemeFolderName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        devicesDeviceInfoRuntimeId
        devicesRingGroupId
        __typename
      }
      Start
      End
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      gstreamerDeviceId
      __typename
    }
  }
`;
export const deleteGstreamer = /* GraphQL */ `
  mutation DeleteGstreamer(
    $input: DeleteGstreamerInput!
    $condition: ModelGstreamerConditionInput
  ) {
    deleteGstreamer(input: $input, condition: $condition) {
      TaskID
      InUse
      Status
      StreamName
      Device {
        id
        Name
        PhysicalAddress
        DoorNumber
        VciConfigName
        ViaConfigName
        DeviceInfoRuntime {
          id
          ErrorsCode
          Status
          PowerCycles
          IpAddress
          MacAddress
          FirmwareVersion
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SIPAccount {
          items {
            id
            Name
            Type
            SIPOwner
            PrimaryAddress
            SecondaryAddress
            PbxUsername
            PbxPassword
            PbxExtension
            ClientPeerId
            StreamName
            PhoneNumber
            DeviceID
            Port
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        modelsID
        buildingsID
        Models {
          id
          Name
          Description
          Resolution
          IsStreaming
          IsSipPhone
          IsSipPublic
          IsSipPrivate
          MaxMemorySizeInKB
          DeviceType
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamIpAddress
        RingGroup {
          id
          Name
          Extension
          Callees {
            items {
              id
              Number
              LinkNumber
              LinkEmail
              Priority
              SIPAccount {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              RingGroupID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              calleesSIPAccountId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Time
          CountryCode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamPort
        GroupID
        ThemeFolderName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        devicesDeviceInfoRuntimeId
        devicesRingGroupId
        __typename
      }
      Start
      End
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      gstreamerDeviceId
      __typename
    }
  }
`;
export const createMikrotikClients = /* GraphQL */ `
  mutation CreateMikrotikClients(
    $input: CreateMikrotikClientsInput!
    $condition: ModelMikrotikClientsConditionInput
  ) {
    createMikrotikClients(input: $input, condition: $condition) {
      id
      DstAddress
      RemoteAddress
      Username
      Password
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMikrotikClients = /* GraphQL */ `
  mutation UpdateMikrotikClients(
    $input: UpdateMikrotikClientsInput!
    $condition: ModelMikrotikClientsConditionInput
  ) {
    updateMikrotikClients(input: $input, condition: $condition) {
      id
      DstAddress
      RemoteAddress
      Username
      Password
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMikrotikClients = /* GraphQL */ `
  mutation DeleteMikrotikClients(
    $input: DeleteMikrotikClientsInput!
    $condition: ModelMikrotikClientsConditionInput
  ) {
    deleteMikrotikClients(input: $input, condition: $condition) {
      id
      DstAddress
      RemoteAddress
      Username
      Password
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createStripeManagement = /* GraphQL */ `
  mutation CreateStripeManagement(
    $input: CreateStripeManagementInput!
    $condition: ModelStripeManagementConditionInput
  ) {
    createStripeManagement(input: $input, condition: $condition) {
      id
      SubscriptionProductsQuantity
      UsedProductsQuantity
      TotalPricePerMonth
      PaymentStatus
      SubscriptionEnd
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateStripeManagement = /* GraphQL */ `
  mutation UpdateStripeManagement(
    $input: UpdateStripeManagementInput!
    $condition: ModelStripeManagementConditionInput
  ) {
    updateStripeManagement(input: $input, condition: $condition) {
      id
      SubscriptionProductsQuantity
      UsedProductsQuantity
      TotalPricePerMonth
      PaymentStatus
      SubscriptionEnd
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteStripeManagement = /* GraphQL */ `
  mutation DeleteStripeManagement(
    $input: DeleteStripeManagementInput!
    $condition: ModelStripeManagementConditionInput
  ) {
    deleteStripeManagement(input: $input, condition: $condition) {
      id
      SubscriptionProductsQuantity
      UsedProductsQuantity
      TotalPricePerMonth
      PaymentStatus
      SubscriptionEnd
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBFGVersion = /* GraphQL */ `
  mutation CreateBFGVersion(
    $input: CreateBFGVersionInput!
    $condition: ModelBFGVersionConditionInput
  ) {
    createBFGVersion(input: $input, condition: $condition) {
      id
      Name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBFGVersion = /* GraphQL */ `
  mutation UpdateBFGVersion(
    $input: UpdateBFGVersionInput!
    $condition: ModelBFGVersionConditionInput
  ) {
    updateBFGVersion(input: $input, condition: $condition) {
      id
      Name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBFGVersion = /* GraphQL */ `
  mutation DeleteBFGVersion(
    $input: DeleteBFGVersionInput!
    $condition: ModelBFGVersionConditionInput
  ) {
    deleteBFGVersion(input: $input, condition: $condition) {
      id
      Name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPingBuilding = /* GraphQL */ `
  mutation CreatePingBuilding(
    $input: CreatePingBuildingInput!
    $condition: ModelPingBuildingConditionInput
  ) {
    createPingBuilding(input: $input, condition: $condition) {
      id
      IpAddress
      Status
      MacAddress
      FirmwareVersion
      ErrorsCode
      PowerCycles
      ForceUpdateBFG
      ForceUpdateFirmware
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePingBuilding = /* GraphQL */ `
  mutation UpdatePingBuilding(
    $input: UpdatePingBuildingInput!
    $condition: ModelPingBuildingConditionInput
  ) {
    updatePingBuilding(input: $input, condition: $condition) {
      id
      IpAddress
      Status
      MacAddress
      FirmwareVersion
      ErrorsCode
      PowerCycles
      ForceUpdateBFG
      ForceUpdateFirmware
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePingBuilding = /* GraphQL */ `
  mutation DeletePingBuilding(
    $input: DeletePingBuildingInput!
    $condition: ModelPingBuildingConditionInput
  ) {
    deletePingBuilding(input: $input, condition: $condition) {
      id
      IpAddress
      Status
      MacAddress
      FirmwareVersion
      ErrorsCode
      PowerCycles
      ForceUpdateBFG
      ForceUpdateFirmware
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCallees = /* GraphQL */ `
  mutation CreateCallees(
    $input: CreateCalleesInput!
    $condition: ModelCalleesConditionInput
  ) {
    createCallees(input: $input, condition: $condition) {
      id
      Number
      LinkNumber
      LinkEmail
      Priority
      SIPAccount {
        id
        Name
        Type
        SIPOwner
        PrimaryAddress
        SecondaryAddress
        PbxUsername
        PbxPassword
        PbxExtension
        ClientPeerId
        StreamName
        PhoneNumber
        DeviceID
        Port
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      RingGroupID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      calleesSIPAccountId
      __typename
    }
  }
`;
export const updateCallees = /* GraphQL */ `
  mutation UpdateCallees(
    $input: UpdateCalleesInput!
    $condition: ModelCalleesConditionInput
  ) {
    updateCallees(input: $input, condition: $condition) {
      id
      Number
      LinkNumber
      LinkEmail
      Priority
      SIPAccount {
        id
        Name
        Type
        SIPOwner
        PrimaryAddress
        SecondaryAddress
        PbxUsername
        PbxPassword
        PbxExtension
        ClientPeerId
        StreamName
        PhoneNumber
        DeviceID
        Port
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      RingGroupID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      calleesSIPAccountId
      __typename
    }
  }
`;
export const deleteCallees = /* GraphQL */ `
  mutation DeleteCallees(
    $input: DeleteCalleesInput!
    $condition: ModelCalleesConditionInput
  ) {
    deleteCallees(input: $input, condition: $condition) {
      id
      Number
      LinkNumber
      LinkEmail
      Priority
      SIPAccount {
        id
        Name
        Type
        SIPOwner
        PrimaryAddress
        SecondaryAddress
        PbxUsername
        PbxPassword
        PbxExtension
        ClientPeerId
        StreamName
        PhoneNumber
        DeviceID
        Port
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      RingGroupID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      calleesSIPAccountId
      __typename
    }
  }
`;
export const createRingGroup = /* GraphQL */ `
  mutation CreateRingGroup(
    $input: CreateRingGroupInput!
    $condition: ModelRingGroupConditionInput
  ) {
    createRingGroup(input: $input, condition: $condition) {
      id
      Name
      Extension
      Callees {
        items {
          id
          Number
          LinkNumber
          LinkEmail
          Priority
          SIPAccount {
            id
            Name
            Type
            SIPOwner
            PrimaryAddress
            SecondaryAddress
            PbxUsername
            PbxPassword
            PbxExtension
            ClientPeerId
            StreamName
            PhoneNumber
            DeviceID
            Port
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          RingGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          calleesSIPAccountId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Time
      CountryCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRingGroup = /* GraphQL */ `
  mutation UpdateRingGroup(
    $input: UpdateRingGroupInput!
    $condition: ModelRingGroupConditionInput
  ) {
    updateRingGroup(input: $input, condition: $condition) {
      id
      Name
      Extension
      Callees {
        items {
          id
          Number
          LinkNumber
          LinkEmail
          Priority
          SIPAccount {
            id
            Name
            Type
            SIPOwner
            PrimaryAddress
            SecondaryAddress
            PbxUsername
            PbxPassword
            PbxExtension
            ClientPeerId
            StreamName
            PhoneNumber
            DeviceID
            Port
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          RingGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          calleesSIPAccountId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Time
      CountryCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRingGroup = /* GraphQL */ `
  mutation DeleteRingGroup(
    $input: DeleteRingGroupInput!
    $condition: ModelRingGroupConditionInput
  ) {
    deleteRingGroup(input: $input, condition: $condition) {
      id
      Name
      Extension
      Callees {
        items {
          id
          Number
          LinkNumber
          LinkEmail
          Priority
          SIPAccount {
            id
            Name
            Type
            SIPOwner
            PrimaryAddress
            SecondaryAddress
            PbxUsername
            PbxPassword
            PbxExtension
            ClientPeerId
            StreamName
            PhoneNumber
            DeviceID
            Port
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          RingGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          calleesSIPAccountId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Time
      CountryCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      Street1
      Street2
      City
      Country
      Province
      PostalCode
      Phone
      Fax
      ContactName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      Street1
      Street2
      City
      Country
      Province
      PostalCode
      Phone
      Fax
      ContactName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      Street1
      Street2
      City
      Country
      Province
      PostalCode
      Phone
      Fax
      ContactName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContractorFirstResponder = /* GraphQL */ `
  mutation CreateContractorFirstResponder(
    $input: CreateContractorFirstResponderInput!
    $condition: ModelContractorFirstResponderConditionInput
  ) {
    createContractorFirstResponder(input: $input, condition: $condition) {
      id
      Number
      Priority
      contractorsID
      Name
      Availability
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContractorFirstResponder = /* GraphQL */ `
  mutation UpdateContractorFirstResponder(
    $input: UpdateContractorFirstResponderInput!
    $condition: ModelContractorFirstResponderConditionInput
  ) {
    updateContractorFirstResponder(input: $input, condition: $condition) {
      id
      Number
      Priority
      contractorsID
      Name
      Availability
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContractorFirstResponder = /* GraphQL */ `
  mutation DeleteContractorFirstResponder(
    $input: DeleteContractorFirstResponderInput!
    $condition: ModelContractorFirstResponderConditionInput
  ) {
    deleteContractorFirstResponder(input: $input, condition: $condition) {
      id
      Number
      Priority
      contractorsID
      Name
      Availability
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBuildings = /* GraphQL */ `
  mutation CreateBuildings(
    $input: CreateBuildingsInput!
    $condition: ModelBuildingsConditionInput
  ) {
    createBuildings(input: $input, condition: $condition) {
      id
      Name
      Phone
      SubAccountId
      RefreshRateInSec
      IsFetchingWeather
      ownersID
      Address {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BFGVersion {
        id
        Name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StripeManagement {
        id
        SubscriptionProductsQuantity
        UsedProductsQuantity
        TotalPricePerMonth
        PaymentStatus
        SubscriptionEnd
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Devices {
        items {
          id
          Name
          PhysicalAddress
          DoorNumber
          VciConfigName
          ViaConfigName
          DeviceInfoRuntime {
            id
            ErrorsCode
            Status
            PowerCycles
            IpAddress
            MacAddress
            FirmwareVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          SIPAccount {
            items {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          modelsID
          buildingsID
          Models {
            id
            Name
            Description
            Resolution
            IsStreaming
            IsSipPhone
            IsSipPublic
            IsSipPrivate
            MaxMemorySizeInKB
            DeviceType
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamIpAddress
          RingGroup {
            id
            Name
            Extension
            Callees {
              items {
                id
                Number
                LinkNumber
                LinkEmail
                Priority
                SIPAccount {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                RingGroupID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                calleesSIPAccountId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Time
            CountryCode
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamPort
          GroupID
          ThemeFolderName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          devicesDeviceInfoRuntimeId
          devicesRingGroupId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      MikrotikClients {
        id
        DstAddress
        RemoteAddress
        Username
        Password
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      contractorsID
      Contractors {
        id
        Name
        Email
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ContractorFirstResponder {
          items {
            id
            Number
            Priority
            contractorsID
            Name
            Availability
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contractorsAddressId
        __typename
      }
      Owners {
        id
        BusinessName
        Email
        BillingEmail
        PhoneNumber
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        ownersAddressId
        __typename
      }
      PingBuilding {
        id
        IpAddress
        Status
        MacAddress
        FirmwareVersion
        ErrorsCode
        PowerCycles
        ForceUpdateBFG
        ForceUpdateFirmware
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      buildingsAddressId
      buildingsBFGVersionId
      buildingsStripeManagementId
      buildingsMikrotikClientsId
      buildingsPingBuildingId
      __typename
    }
  }
`;
export const updateBuildings = /* GraphQL */ `
  mutation UpdateBuildings(
    $input: UpdateBuildingsInput!
    $condition: ModelBuildingsConditionInput
  ) {
    updateBuildings(input: $input, condition: $condition) {
      id
      Name
      Phone
      SubAccountId
      RefreshRateInSec
      IsFetchingWeather
      ownersID
      Address {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BFGVersion {
        id
        Name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StripeManagement {
        id
        SubscriptionProductsQuantity
        UsedProductsQuantity
        TotalPricePerMonth
        PaymentStatus
        SubscriptionEnd
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Devices {
        items {
          id
          Name
          PhysicalAddress
          DoorNumber
          VciConfigName
          ViaConfigName
          DeviceInfoRuntime {
            id
            ErrorsCode
            Status
            PowerCycles
            IpAddress
            MacAddress
            FirmwareVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          SIPAccount {
            items {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          modelsID
          buildingsID
          Models {
            id
            Name
            Description
            Resolution
            IsStreaming
            IsSipPhone
            IsSipPublic
            IsSipPrivate
            MaxMemorySizeInKB
            DeviceType
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamIpAddress
          RingGroup {
            id
            Name
            Extension
            Callees {
              items {
                id
                Number
                LinkNumber
                LinkEmail
                Priority
                SIPAccount {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                RingGroupID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                calleesSIPAccountId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Time
            CountryCode
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamPort
          GroupID
          ThemeFolderName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          devicesDeviceInfoRuntimeId
          devicesRingGroupId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      MikrotikClients {
        id
        DstAddress
        RemoteAddress
        Username
        Password
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      contractorsID
      Contractors {
        id
        Name
        Email
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ContractorFirstResponder {
          items {
            id
            Number
            Priority
            contractorsID
            Name
            Availability
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contractorsAddressId
        __typename
      }
      Owners {
        id
        BusinessName
        Email
        BillingEmail
        PhoneNumber
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        ownersAddressId
        __typename
      }
      PingBuilding {
        id
        IpAddress
        Status
        MacAddress
        FirmwareVersion
        ErrorsCode
        PowerCycles
        ForceUpdateBFG
        ForceUpdateFirmware
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      buildingsAddressId
      buildingsBFGVersionId
      buildingsStripeManagementId
      buildingsMikrotikClientsId
      buildingsPingBuildingId
      __typename
    }
  }
`;
export const deleteBuildings = /* GraphQL */ `
  mutation DeleteBuildings(
    $input: DeleteBuildingsInput!
    $condition: ModelBuildingsConditionInput
  ) {
    deleteBuildings(input: $input, condition: $condition) {
      id
      Name
      Phone
      SubAccountId
      RefreshRateInSec
      IsFetchingWeather
      ownersID
      Address {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BFGVersion {
        id
        Name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StripeManagement {
        id
        SubscriptionProductsQuantity
        UsedProductsQuantity
        TotalPricePerMonth
        PaymentStatus
        SubscriptionEnd
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Devices {
        items {
          id
          Name
          PhysicalAddress
          DoorNumber
          VciConfigName
          ViaConfigName
          DeviceInfoRuntime {
            id
            ErrorsCode
            Status
            PowerCycles
            IpAddress
            MacAddress
            FirmwareVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          SIPAccount {
            items {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          modelsID
          buildingsID
          Models {
            id
            Name
            Description
            Resolution
            IsStreaming
            IsSipPhone
            IsSipPublic
            IsSipPrivate
            MaxMemorySizeInKB
            DeviceType
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamIpAddress
          RingGroup {
            id
            Name
            Extension
            Callees {
              items {
                id
                Number
                LinkNumber
                LinkEmail
                Priority
                SIPAccount {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                RingGroupID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                calleesSIPAccountId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Time
            CountryCode
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamPort
          GroupID
          ThemeFolderName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          devicesDeviceInfoRuntimeId
          devicesRingGroupId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      MikrotikClients {
        id
        DstAddress
        RemoteAddress
        Username
        Password
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      contractorsID
      Contractors {
        id
        Name
        Email
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ContractorFirstResponder {
          items {
            id
            Number
            Priority
            contractorsID
            Name
            Availability
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contractorsAddressId
        __typename
      }
      Owners {
        id
        BusinessName
        Email
        BillingEmail
        PhoneNumber
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        ownersAddressId
        __typename
      }
      PingBuilding {
        id
        IpAddress
        Status
        MacAddress
        FirmwareVersion
        ErrorsCode
        PowerCycles
        ForceUpdateBFG
        ForceUpdateFirmware
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      buildingsAddressId
      buildingsBFGVersionId
      buildingsStripeManagementId
      buildingsMikrotikClientsId
      buildingsPingBuildingId
      __typename
    }
  }
`;
export const createOwners = /* GraphQL */ `
  mutation CreateOwners(
    $input: CreateOwnersInput!
    $condition: ModelOwnersConditionInput
  ) {
    createOwners(input: $input, condition: $condition) {
      id
      BusinessName
      Email
      BillingEmail
      PhoneNumber
      Buildings {
        items {
          id
          Name
          Phone
          SubAccountId
          RefreshRateInSec
          IsFetchingWeather
          ownersID
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BFGVersion {
            id
            Name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StripeManagement {
            id
            SubscriptionProductsQuantity
            UsedProductsQuantity
            TotalPricePerMonth
            PaymentStatus
            SubscriptionEnd
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          MikrotikClients {
            id
            DstAddress
            RemoteAddress
            Username
            Password
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          contractorsID
          Contractors {
            id
            Name
            Email
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            ContractorFirstResponder {
              items {
                id
                Number
                Priority
                contractorsID
                Name
                Availability
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contractorsAddressId
            __typename
          }
          Owners {
            id
            BusinessName
            Email
            BillingEmail
            PhoneNumber
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            ownersAddressId
            __typename
          }
          PingBuilding {
            id
            IpAddress
            Status
            MacAddress
            FirmwareVersion
            ErrorsCode
            PowerCycles
            ForceUpdateBFG
            ForceUpdateFirmware
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          buildingsAddressId
          buildingsBFGVersionId
          buildingsStripeManagementId
          buildingsMikrotikClientsId
          buildingsPingBuildingId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Address {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ConfirmationStatus
      Status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      ownersAddressId
      __typename
    }
  }
`;
export const updateOwners = /* GraphQL */ `
  mutation UpdateOwners(
    $input: UpdateOwnersInput!
    $condition: ModelOwnersConditionInput
  ) {
    updateOwners(input: $input, condition: $condition) {
      id
      BusinessName
      Email
      BillingEmail
      PhoneNumber
      Buildings {
        items {
          id
          Name
          Phone
          SubAccountId
          RefreshRateInSec
          IsFetchingWeather
          ownersID
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BFGVersion {
            id
            Name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StripeManagement {
            id
            SubscriptionProductsQuantity
            UsedProductsQuantity
            TotalPricePerMonth
            PaymentStatus
            SubscriptionEnd
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          MikrotikClients {
            id
            DstAddress
            RemoteAddress
            Username
            Password
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          contractorsID
          Contractors {
            id
            Name
            Email
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            ContractorFirstResponder {
              items {
                id
                Number
                Priority
                contractorsID
                Name
                Availability
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contractorsAddressId
            __typename
          }
          Owners {
            id
            BusinessName
            Email
            BillingEmail
            PhoneNumber
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            ownersAddressId
            __typename
          }
          PingBuilding {
            id
            IpAddress
            Status
            MacAddress
            FirmwareVersion
            ErrorsCode
            PowerCycles
            ForceUpdateBFG
            ForceUpdateFirmware
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          buildingsAddressId
          buildingsBFGVersionId
          buildingsStripeManagementId
          buildingsMikrotikClientsId
          buildingsPingBuildingId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Address {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ConfirmationStatus
      Status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      ownersAddressId
      __typename
    }
  }
`;
export const deleteOwners = /* GraphQL */ `
  mutation DeleteOwners(
    $input: DeleteOwnersInput!
    $condition: ModelOwnersConditionInput
  ) {
    deleteOwners(input: $input, condition: $condition) {
      id
      BusinessName
      Email
      BillingEmail
      PhoneNumber
      Buildings {
        items {
          id
          Name
          Phone
          SubAccountId
          RefreshRateInSec
          IsFetchingWeather
          ownersID
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BFGVersion {
            id
            Name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StripeManagement {
            id
            SubscriptionProductsQuantity
            UsedProductsQuantity
            TotalPricePerMonth
            PaymentStatus
            SubscriptionEnd
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          MikrotikClients {
            id
            DstAddress
            RemoteAddress
            Username
            Password
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          contractorsID
          Contractors {
            id
            Name
            Email
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            ContractorFirstResponder {
              items {
                id
                Number
                Priority
                contractorsID
                Name
                Availability
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contractorsAddressId
            __typename
          }
          Owners {
            id
            BusinessName
            Email
            BillingEmail
            PhoneNumber
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            ownersAddressId
            __typename
          }
          PingBuilding {
            id
            IpAddress
            Status
            MacAddress
            FirmwareVersion
            ErrorsCode
            PowerCycles
            ForceUpdateBFG
            ForceUpdateFirmware
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          buildingsAddressId
          buildingsBFGVersionId
          buildingsStripeManagementId
          buildingsMikrotikClientsId
          buildingsPingBuildingId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Address {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ConfirmationStatus
      Status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      ownersAddressId
      __typename
    }
  }
`;
export const createContractors = /* GraphQL */ `
  mutation CreateContractors(
    $input: CreateContractorsInput!
    $condition: ModelContractorsConditionInput
  ) {
    createContractors(input: $input, condition: $condition) {
      id
      Name
      Email
      Buildings {
        items {
          id
          Name
          Phone
          SubAccountId
          RefreshRateInSec
          IsFetchingWeather
          ownersID
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BFGVersion {
            id
            Name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StripeManagement {
            id
            SubscriptionProductsQuantity
            UsedProductsQuantity
            TotalPricePerMonth
            PaymentStatus
            SubscriptionEnd
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          MikrotikClients {
            id
            DstAddress
            RemoteAddress
            Username
            Password
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          contractorsID
          Contractors {
            id
            Name
            Email
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            ContractorFirstResponder {
              items {
                id
                Number
                Priority
                contractorsID
                Name
                Availability
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contractorsAddressId
            __typename
          }
          Owners {
            id
            BusinessName
            Email
            BillingEmail
            PhoneNumber
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            ownersAddressId
            __typename
          }
          PingBuilding {
            id
            IpAddress
            Status
            MacAddress
            FirmwareVersion
            ErrorsCode
            PowerCycles
            ForceUpdateBFG
            ForceUpdateFirmware
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          buildingsAddressId
          buildingsBFGVersionId
          buildingsStripeManagementId
          buildingsMikrotikClientsId
          buildingsPingBuildingId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ContractorFirstResponder {
        items {
          id
          Number
          Priority
          contractorsID
          Name
          Availability
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Address {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ConfirmationStatus
      Status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      contractorsAddressId
      __typename
    }
  }
`;
export const updateContractors = /* GraphQL */ `
  mutation UpdateContractors(
    $input: UpdateContractorsInput!
    $condition: ModelContractorsConditionInput
  ) {
    updateContractors(input: $input, condition: $condition) {
      id
      Name
      Email
      Buildings {
        items {
          id
          Name
          Phone
          SubAccountId
          RefreshRateInSec
          IsFetchingWeather
          ownersID
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BFGVersion {
            id
            Name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StripeManagement {
            id
            SubscriptionProductsQuantity
            UsedProductsQuantity
            TotalPricePerMonth
            PaymentStatus
            SubscriptionEnd
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          MikrotikClients {
            id
            DstAddress
            RemoteAddress
            Username
            Password
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          contractorsID
          Contractors {
            id
            Name
            Email
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            ContractorFirstResponder {
              items {
                id
                Number
                Priority
                contractorsID
                Name
                Availability
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contractorsAddressId
            __typename
          }
          Owners {
            id
            BusinessName
            Email
            BillingEmail
            PhoneNumber
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            ownersAddressId
            __typename
          }
          PingBuilding {
            id
            IpAddress
            Status
            MacAddress
            FirmwareVersion
            ErrorsCode
            PowerCycles
            ForceUpdateBFG
            ForceUpdateFirmware
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          buildingsAddressId
          buildingsBFGVersionId
          buildingsStripeManagementId
          buildingsMikrotikClientsId
          buildingsPingBuildingId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ContractorFirstResponder {
        items {
          id
          Number
          Priority
          contractorsID
          Name
          Availability
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Address {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ConfirmationStatus
      Status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      contractorsAddressId
      __typename
    }
  }
`;
export const deleteContractors = /* GraphQL */ `
  mutation DeleteContractors(
    $input: DeleteContractorsInput!
    $condition: ModelContractorsConditionInput
  ) {
    deleteContractors(input: $input, condition: $condition) {
      id
      Name
      Email
      Buildings {
        items {
          id
          Name
          Phone
          SubAccountId
          RefreshRateInSec
          IsFetchingWeather
          ownersID
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BFGVersion {
            id
            Name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StripeManagement {
            id
            SubscriptionProductsQuantity
            UsedProductsQuantity
            TotalPricePerMonth
            PaymentStatus
            SubscriptionEnd
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          MikrotikClients {
            id
            DstAddress
            RemoteAddress
            Username
            Password
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          contractorsID
          Contractors {
            id
            Name
            Email
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            ContractorFirstResponder {
              items {
                id
                Number
                Priority
                contractorsID
                Name
                Availability
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contractorsAddressId
            __typename
          }
          Owners {
            id
            BusinessName
            Email
            BillingEmail
            PhoneNumber
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            ownersAddressId
            __typename
          }
          PingBuilding {
            id
            IpAddress
            Status
            MacAddress
            FirmwareVersion
            ErrorsCode
            PowerCycles
            ForceUpdateBFG
            ForceUpdateFirmware
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          buildingsAddressId
          buildingsBFGVersionId
          buildingsStripeManagementId
          buildingsMikrotikClientsId
          buildingsPingBuildingId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ContractorFirstResponder {
        items {
          id
          Number
          Priority
          contractorsID
          Name
          Availability
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Address {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ConfirmationStatus
      Status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      contractorsAddressId
      __typename
    }
  }
`;
export const createModels = /* GraphQL */ `
  mutation CreateModels(
    $input: CreateModelsInput!
    $condition: ModelModelsConditionInput
  ) {
    createModels(input: $input, condition: $condition) {
      id
      Name
      Description
      Resolution
      IsStreaming
      IsSipPhone
      IsSipPublic
      IsSipPrivate
      MaxMemorySizeInKB
      DeviceType
      Devices {
        items {
          id
          Name
          PhysicalAddress
          DoorNumber
          VciConfigName
          ViaConfigName
          DeviceInfoRuntime {
            id
            ErrorsCode
            Status
            PowerCycles
            IpAddress
            MacAddress
            FirmwareVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          SIPAccount {
            items {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          modelsID
          buildingsID
          Models {
            id
            Name
            Description
            Resolution
            IsStreaming
            IsSipPhone
            IsSipPublic
            IsSipPrivate
            MaxMemorySizeInKB
            DeviceType
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamIpAddress
          RingGroup {
            id
            Name
            Extension
            Callees {
              items {
                id
                Number
                LinkNumber
                LinkEmail
                Priority
                SIPAccount {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                RingGroupID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                calleesSIPAccountId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Time
            CountryCode
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamPort
          GroupID
          ThemeFolderName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          devicesDeviceInfoRuntimeId
          devicesRingGroupId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateModels = /* GraphQL */ `
  mutation UpdateModels(
    $input: UpdateModelsInput!
    $condition: ModelModelsConditionInput
  ) {
    updateModels(input: $input, condition: $condition) {
      id
      Name
      Description
      Resolution
      IsStreaming
      IsSipPhone
      IsSipPublic
      IsSipPrivate
      MaxMemorySizeInKB
      DeviceType
      Devices {
        items {
          id
          Name
          PhysicalAddress
          DoorNumber
          VciConfigName
          ViaConfigName
          DeviceInfoRuntime {
            id
            ErrorsCode
            Status
            PowerCycles
            IpAddress
            MacAddress
            FirmwareVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          SIPAccount {
            items {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          modelsID
          buildingsID
          Models {
            id
            Name
            Description
            Resolution
            IsStreaming
            IsSipPhone
            IsSipPublic
            IsSipPrivate
            MaxMemorySizeInKB
            DeviceType
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamIpAddress
          RingGroup {
            id
            Name
            Extension
            Callees {
              items {
                id
                Number
                LinkNumber
                LinkEmail
                Priority
                SIPAccount {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                RingGroupID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                calleesSIPAccountId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Time
            CountryCode
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamPort
          GroupID
          ThemeFolderName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          devicesDeviceInfoRuntimeId
          devicesRingGroupId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteModels = /* GraphQL */ `
  mutation DeleteModels(
    $input: DeleteModelsInput!
    $condition: ModelModelsConditionInput
  ) {
    deleteModels(input: $input, condition: $condition) {
      id
      Name
      Description
      Resolution
      IsStreaming
      IsSipPhone
      IsSipPublic
      IsSipPrivate
      MaxMemorySizeInKB
      DeviceType
      Devices {
        items {
          id
          Name
          PhysicalAddress
          DoorNumber
          VciConfigName
          ViaConfigName
          DeviceInfoRuntime {
            id
            ErrorsCode
            Status
            PowerCycles
            IpAddress
            MacAddress
            FirmwareVersion
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          SIPAccount {
            items {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          modelsID
          buildingsID
          Models {
            id
            Name
            Description
            Resolution
            IsStreaming
            IsSipPhone
            IsSipPublic
            IsSipPrivate
            MaxMemorySizeInKB
            DeviceType
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamIpAddress
          RingGroup {
            id
            Name
            Extension
            Callees {
              items {
                id
                Number
                LinkNumber
                LinkEmail
                Priority
                SIPAccount {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                RingGroupID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                calleesSIPAccountId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Time
            CountryCode
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamPort
          GroupID
          ThemeFolderName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          devicesDeviceInfoRuntimeId
          devicesRingGroupId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDevices = /* GraphQL */ `
  mutation CreateDevices(
    $input: CreateDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    createDevices(input: $input, condition: $condition) {
      id
      Name
      PhysicalAddress
      DoorNumber
      VciConfigName
      ViaConfigName
      DeviceInfoRuntime {
        id
        ErrorsCode
        Status
        PowerCycles
        IpAddress
        MacAddress
        FirmwareVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      SIPAccount {
        items {
          id
          Name
          Type
          SIPOwner
          PrimaryAddress
          SecondaryAddress
          PbxUsername
          PbxPassword
          PbxExtension
          ClientPeerId
          StreamName
          PhoneNumber
          DeviceID
          Port
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      modelsID
      buildingsID
      Models {
        id
        Name
        Description
        Resolution
        IsStreaming
        IsSipPhone
        IsSipPublic
        IsSipPrivate
        MaxMemorySizeInKB
        DeviceType
        Devices {
          items {
            id
            Name
            PhysicalAddress
            DoorNumber
            VciConfigName
            ViaConfigName
            DeviceInfoRuntime {
              id
              ErrorsCode
              Status
              PowerCycles
              IpAddress
              MacAddress
              FirmwareVersion
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            SIPAccount {
              items {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            modelsID
            buildingsID
            Models {
              id
              Name
              Description
              Resolution
              IsStreaming
              IsSipPhone
              IsSipPublic
              IsSipPrivate
              MaxMemorySizeInKB
              DeviceType
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamIpAddress
            RingGroup {
              id
              Name
              Extension
              Callees {
                items {
                  id
                  Number
                  LinkNumber
                  LinkEmail
                  Priority
                  RingGroupID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  calleesSIPAccountId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Time
              CountryCode
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamPort
            GroupID
            ThemeFolderName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            devicesDeviceInfoRuntimeId
            devicesRingGroupId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StreamIpAddress
      RingGroup {
        id
        Name
        Extension
        Callees {
          items {
            id
            Number
            LinkNumber
            LinkEmail
            Priority
            SIPAccount {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            RingGroupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calleesSIPAccountId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Time
        CountryCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StreamPort
      GroupID
      ThemeFolderName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      devicesDeviceInfoRuntimeId
      devicesRingGroupId
      __typename
    }
  }
`;
export const updateDevices = /* GraphQL */ `
  mutation UpdateDevices(
    $input: UpdateDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    updateDevices(input: $input, condition: $condition) {
      id
      Name
      PhysicalAddress
      DoorNumber
      VciConfigName
      ViaConfigName
      DeviceInfoRuntime {
        id
        ErrorsCode
        Status
        PowerCycles
        IpAddress
        MacAddress
        FirmwareVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      SIPAccount {
        items {
          id
          Name
          Type
          SIPOwner
          PrimaryAddress
          SecondaryAddress
          PbxUsername
          PbxPassword
          PbxExtension
          ClientPeerId
          StreamName
          PhoneNumber
          DeviceID
          Port
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      modelsID
      buildingsID
      Models {
        id
        Name
        Description
        Resolution
        IsStreaming
        IsSipPhone
        IsSipPublic
        IsSipPrivate
        MaxMemorySizeInKB
        DeviceType
        Devices {
          items {
            id
            Name
            PhysicalAddress
            DoorNumber
            VciConfigName
            ViaConfigName
            DeviceInfoRuntime {
              id
              ErrorsCode
              Status
              PowerCycles
              IpAddress
              MacAddress
              FirmwareVersion
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            SIPAccount {
              items {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            modelsID
            buildingsID
            Models {
              id
              Name
              Description
              Resolution
              IsStreaming
              IsSipPhone
              IsSipPublic
              IsSipPrivate
              MaxMemorySizeInKB
              DeviceType
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamIpAddress
            RingGroup {
              id
              Name
              Extension
              Callees {
                items {
                  id
                  Number
                  LinkNumber
                  LinkEmail
                  Priority
                  RingGroupID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  calleesSIPAccountId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Time
              CountryCode
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamPort
            GroupID
            ThemeFolderName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            devicesDeviceInfoRuntimeId
            devicesRingGroupId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StreamIpAddress
      RingGroup {
        id
        Name
        Extension
        Callees {
          items {
            id
            Number
            LinkNumber
            LinkEmail
            Priority
            SIPAccount {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            RingGroupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calleesSIPAccountId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Time
        CountryCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StreamPort
      GroupID
      ThemeFolderName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      devicesDeviceInfoRuntimeId
      devicesRingGroupId
      __typename
    }
  }
`;
export const deleteDevices = /* GraphQL */ `
  mutation DeleteDevices(
    $input: DeleteDevicesInput!
    $condition: ModelDevicesConditionInput
  ) {
    deleteDevices(input: $input, condition: $condition) {
      id
      Name
      PhysicalAddress
      DoorNumber
      VciConfigName
      ViaConfigName
      DeviceInfoRuntime {
        id
        ErrorsCode
        Status
        PowerCycles
        IpAddress
        MacAddress
        FirmwareVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      SIPAccount {
        items {
          id
          Name
          Type
          SIPOwner
          PrimaryAddress
          SecondaryAddress
          PbxUsername
          PbxPassword
          PbxExtension
          ClientPeerId
          StreamName
          PhoneNumber
          DeviceID
          Port
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      modelsID
      buildingsID
      Models {
        id
        Name
        Description
        Resolution
        IsStreaming
        IsSipPhone
        IsSipPublic
        IsSipPrivate
        MaxMemorySizeInKB
        DeviceType
        Devices {
          items {
            id
            Name
            PhysicalAddress
            DoorNumber
            VciConfigName
            ViaConfigName
            DeviceInfoRuntime {
              id
              ErrorsCode
              Status
              PowerCycles
              IpAddress
              MacAddress
              FirmwareVersion
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            SIPAccount {
              items {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            modelsID
            buildingsID
            Models {
              id
              Name
              Description
              Resolution
              IsStreaming
              IsSipPhone
              IsSipPublic
              IsSipPrivate
              MaxMemorySizeInKB
              DeviceType
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamIpAddress
            RingGroup {
              id
              Name
              Extension
              Callees {
                items {
                  id
                  Number
                  LinkNumber
                  LinkEmail
                  Priority
                  RingGroupID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  calleesSIPAccountId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Time
              CountryCode
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamPort
            GroupID
            ThemeFolderName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            devicesDeviceInfoRuntimeId
            devicesRingGroupId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StreamIpAddress
      RingGroup {
        id
        Name
        Extension
        Callees {
          items {
            id
            Number
            LinkNumber
            LinkEmail
            Priority
            SIPAccount {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            RingGroupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calleesSIPAccountId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Time
        CountryCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StreamPort
      GroupID
      ThemeFolderName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      devicesDeviceInfoRuntimeId
      devicesRingGroupId
      __typename
    }
  }
`;
export const createSIPAccount = /* GraphQL */ `
  mutation CreateSIPAccount(
    $input: CreateSIPAccountInput!
    $condition: ModelSIPAccountConditionInput
  ) {
    createSIPAccount(input: $input, condition: $condition) {
      id
      Name
      Type
      SIPOwner
      PrimaryAddress
      SecondaryAddress
      PbxUsername
      PbxPassword
      PbxExtension
      ClientPeerId
      StreamName
      PhoneNumber
      DeviceID
      Port
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSIPAccount = /* GraphQL */ `
  mutation UpdateSIPAccount(
    $input: UpdateSIPAccountInput!
    $condition: ModelSIPAccountConditionInput
  ) {
    updateSIPAccount(input: $input, condition: $condition) {
      id
      Name
      Type
      SIPOwner
      PrimaryAddress
      SecondaryAddress
      PbxUsername
      PbxPassword
      PbxExtension
      ClientPeerId
      StreamName
      PhoneNumber
      DeviceID
      Port
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSIPAccount = /* GraphQL */ `
  mutation DeleteSIPAccount(
    $input: DeleteSIPAccountInput!
    $condition: ModelSIPAccountConditionInput
  ) {
    deleteSIPAccount(input: $input, condition: $condition) {
      id
      Name
      Type
      SIPOwner
      PrimaryAddress
      SecondaryAddress
      PbxUsername
      PbxPassword
      PbxExtension
      ClientPeerId
      StreamName
      PhoneNumber
      DeviceID
      Port
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDeviceInfoRuntime = /* GraphQL */ `
  mutation CreateDeviceInfoRuntime(
    $input: CreateDeviceInfoRuntimeInput!
    $condition: ModelDeviceInfoRuntimeConditionInput
  ) {
    createDeviceInfoRuntime(input: $input, condition: $condition) {
      id
      ErrorsCode
      Status
      PowerCycles
      IpAddress
      MacAddress
      FirmwareVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDeviceInfoRuntime = /* GraphQL */ `
  mutation UpdateDeviceInfoRuntime(
    $input: UpdateDeviceInfoRuntimeInput!
    $condition: ModelDeviceInfoRuntimeConditionInput
  ) {
    updateDeviceInfoRuntime(input: $input, condition: $condition) {
      id
      ErrorsCode
      Status
      PowerCycles
      IpAddress
      MacAddress
      FirmwareVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDeviceInfoRuntime = /* GraphQL */ `
  mutation DeleteDeviceInfoRuntime(
    $input: DeleteDeviceInfoRuntimeInput!
    $condition: ModelDeviceInfoRuntimeConditionInput
  ) {
    deleteDeviceInfoRuntime(input: $input, condition: $condition) {
      id
      ErrorsCode
      Status
      PowerCycles
      IpAddress
      MacAddress
      FirmwareVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
