import React, { useState, useContext, useEffect } from "react";
import FormInputGlobal from "../Form/InputGlobal";
import { FormContextGlobal } from "../Form/FormProvider";
import {
  MAX_LENGTH,
  VALIDATOR_FORMATS,
  requiredValidator,
  fieldFormatValidator,
  maxLengthValidator,
  minValueValidator,
  MIN_VALUE,
  minLengthValidator,
  MIN_LENGTH,
} from "../../constants/RequiredValidator";
import "./css/BuildingForm.css";
import Button from "../Buttons/Button";
import { BUILDING_REFRESH_RATE } from "../../constants/DefaultValues";
import AddressAutocomplete from "../Form/AddressAutocomplete";

const BuildingForm = (props) => {
  const isCreating = props.item ? false : true;
  const [submitButtonText] = useState(isCreating ? "Create" : "Edit");
  const { data, errors, registerInput } = useContext(FormContextGlobal);
  const [addressErrors, setAddressErrors] = useState({});
  const [initialAddress, setInitialAddress] = useState(
    {
      address: data["Street1"] ? data["Street1"] : "",
      apt: data["Street2"] ? data["Street2"] : "",
      city: data["City"] ? data["City"] : "",
      state: data["Province"] ? data["Province"] : "",
      country: data["Country"] ? data["Country"] : "",
      zip: data["PostalCode"] ? data["PostalCode"] : "",
    }
  );
  const postalCodeFormat =
    data["Country"] === "Canada"
      ? VALIDATOR_FORMATS.POSTAL_CODE_CA
      : VALIDATOR_FORMATS.POSTAL_CODE_US;
  const [validators, setValidators] = useState({
    BuildingName: [
      requiredValidator,
      (e) => minLengthValidator(e, MIN_LENGTH.BUILDING_NAME),
      (e) => maxLengthValidator(e, MAX_LENGTH.TEXT),
    ],
    BuildingPhone: [
      requiredValidator,
      (e) => fieldFormatValidator(e, VALIDATOR_FORMATS.NORTH_AMERICAN_PHONE),
    ],
    Street1: [requiredValidator, (e) => maxLengthValidator(e, MAX_LENGTH.TEXT)],
    City: [requiredValidator, (e) => maxLengthValidator(e, MAX_LENGTH.TEXT)],
    Province: [requiredValidator],
    Country: [requiredValidator],
    RefreshRateInSec: [
      requiredValidator,
      (e) => minValueValidator(e, MIN_VALUE.REFRESH_RATE),
    ],
  });
  const[isFetchingWeather, setIsFetchingWeather] = useState(data["IsFetchingWeather"]);

  useEffect(() => {
    setValidators((prevValidators) => ({
      ...prevValidators,
      PostalCode: [
        requiredValidator,
        (e) => fieldFormatValidator(e, postalCodeFormat),
      ],
    }));
  }, [data["Country"]]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      const submittedData = {
        ...data,
        BuildingPhone: data.BuildingPhone.replace(/\D/g, ""),
        Street1: initialAddress.address,
        // Street2: initialAddress.apt,
        City: initialAddress.city,
        Province: initialAddress.state,
        Country: initialAddress.country,
        PostalCode: initialAddress.zip,
        IsFetchingWeather: isFetchingWeather
      };
      props.onSubmit(submittedData);
    }
  };

  const validateForm = () => {
    let errorsBeforeSubmit = [];

    for (const validator in validators) {
      const errors = registerInput(validator, validators[validator])(data[validator]);
      errorsBeforeSubmit.push(
        errors
      );
      switch (validator) {
        case "Street1":
          setAddressErrors((prevErrors) => ({
            ...prevErrors,
            address: errors,
          }));
          break;
        case "City":
          setAddressErrors((prevErrors) => ({
            ...prevErrors,
            city: errors,
          }));
          break;
        case "Province":
          setAddressErrors((prevErrors) => ({
            ...prevErrors,
            state: errors,
          }));
          break;
        case "Country":
          setAddressErrors((prevErrors) => ({
            ...prevErrors,
            country: errors,
          }));
          break;
        case "PostalCode":
          setAddressErrors((prevErrors) => ({
            ...prevErrors,
            zip: errors,
          }));
          break;
        default:
          break;
      }
    }

    let isValid = true;
    for (const field in errorsBeforeSubmit) {
      if (errorsBeforeSubmit[field].length > 0) {
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setInitialAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
    switch (name) {
      case "address":
        data["Street1"] = value;
        setAddressErrors((prevErrors) => ({
          ...prevErrors,
          address: value.length > 0 ? null : ["* This field is required"],
        }));
        break;
      case "city":
        data["City"] = value;
        setAddressErrors((prevErrors) => ({
          ...prevErrors,
          city: value.length > 0 ? null : ["* This field is required"],
        }));
        break;
      case "state":
        data["Province"] = value;
        setAddressErrors((prevErrors) => ({
          ...prevErrors,
          state: value.length > 0 ? null : ["* This field is required"],
        }));
        break;
      case "zip":
        data["PostalCode"] = value;
        setAddressErrors((prevErrors) => ({
          ...prevErrors,
          zip: value.length > 0 ? null : ["* This field is required"],
        }));
        break;
      case "country":
        data["Country"] = value;
        setAddressErrors((prevErrors) => ({
          ...prevErrors,
          country: value.length > 0 ? null : ["* This field is required"],
        }));
        break;
      default:
        break;
    }
  }

  const toggleWeather = (e) => {
    props.onToggleFetchWeather();
    setIsFetchingWeather(e.target.checked);
  }

  return (
    <form
      noValidate
      className="building-form"
      onSubmit={(event) => handleSubmit(event)}
      data-testid="building-form"
    >
      <div data-testid="building-name-input" className="name-input">
        <FormInputGlobal
          type="text"
          autoFocus={true}
          name="BuildingName"
          placeholder="Enter your building name"
          required={isCreating}
          errors={errors.BuildingName}
          validators={validators.BuildingName}
          maxLength={MAX_LENGTH.TEXT}
          label="Building name"
        />
      </div>
      <div data-testid="building-phone-input" className="phone-input">
        <FormInputGlobal
          type="tel"
          placeholder="##########"
          required={true}
          errors={errors.BuildingPhone}
          validators={validators.BuildingPhone}
          maxLength={MAX_LENGTH.TEXT}
          name="BuildingPhone"
          label="Building phone"
        />
      </div>
      <div data-testid="building-refresh-rate-input" className="refresh-rate-input">
        <FormInputGlobal
          type="number"
          placeholder={`${BUILDING_REFRESH_RATE}`}
          required={true}
          min={MIN_VALUE.REFRESH_RATE}
          label="Refresh rate (in seconds)"
          name="RefreshRateInSec"
          errors={errors.RefreshRateInSec}
          validators={validators.RefreshRateInSec}
        />
      </div>
      <AddressAutocomplete
        initialAddress={initialAddress}
        onChange={handleAddressChange}
        addressErrors={addressErrors}
      />
      <div className="form-group-input weather-input">
        <div className="checkbox-container">
          <label className='titreInput' htmlFor="IsFetchingWeather">Fetch weather</label>
          <input
            type="checkbox"
            id="IsFetchingWeather"
            checked={isFetchingWeather}
            className={isFetchingWeather ? "checked" : null}
            name="IsFetchingWeather"
            onChange={toggleWeather}
          />
        </div>
      </div>
        <div id="button-container">
          <Button text={submitButtonText} type="submit" />
        </div>
    </form>
  );
};

export default BuildingForm;
