import React, { useContext, useEffect, useState } from "react";
import { FormProvider } from "../Form/FormProvider";
import { getSIPAccountStormByDeviceIDCustom } from "../../graphql/queries";
import { manageSipAccountStormCustom } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import "./css/EditVoip.css";
import FormInputGlobal from "../Form/InputGlobal";
import { FormContextGlobal } from "../Form/FormProvider";
import Loader from "../../constants/Loader";
import Toggle from "../Form/Toggle";
import { SelectGlobal } from "../Form/SelectGlobal";
import Button from "../Buttons/Button";
import CreateButton from "../Buttons/CreateButton";
import {
    requiredValidator,
    fieldFormatValidator,
    VALIDATOR_FORMATS,
    minLengthValidator,
    MIN_LENGTH,
} from "../../constants/RequiredValidator";
import Modal from "../Modal/Modal";
import { SIPACCOUNT_TYPE_PRIVATE, SIPACCOUNT_TYPE_PUBLIC, SIPOWNER_CUSTOM, SIPOWNER_VIDATECH } from "../../constants/DefaultValues";
import ConfirmModal from "../SharedComponents/ConfirmModal/ConfirmModal";

export default function EditVoip({ deviceID, onClose }) {
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("An error occured");
    const [validators, setValidators] = useState({
        PrimaryAddress: [requiredValidator],
        PbxUsername: [requiredValidator],
        PbxPassword: [requiredValidator],
        PbxExtension: [requiredValidator],
        Name: [requiredValidator, (e) => minLengthValidator(e, MIN_LENGTH.SIP_ACCOUNT_NAME)],
        Port: [(e) => fieldFormatValidator(e, VALIDATOR_FORMATS.PORT)],
        Type: [uniqueVidatechAccountTypeValidor]
    });
    const [SIPAccounts, setSIPAccounts] = useState([]);
    const [originalSIPAccounts, setOriginalSIPAccounts] = useState([{}]);
    const [SIPErrors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isUnsaved, setIsUnsaved] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    function uniqueVidatechAccountTypeValidor() {
        let vidatechTypes = {};

        for (let account of SIPAccounts) {
            if (account.SIPOwner === "VIDATECH") {
                if (vidatechTypes[account.Type]) {
                    return "You can't have two Vidatech accounts with the same type.";
                } else {
                    vidatechTypes[account.Type] = true;
                }
            }
        }
    
        return "";
    }

    useEffect(() => {
        setValidators(prevValidators => {
            return {
                ...prevValidators,
                Type: [uniqueVidatechAccountTypeValidor]
            };
        })
        const sipErrors = [];
        SIPAccounts.forEach((sipAccount) => {
            sipErrors.push({sipAccountId: sipAccount.id, errors: []});
        });

        for (let i = 0; i < SIPAccounts.length; i++) {
            if (validators["Type"].length > 0) {
                let errors = validators["Type"].map((v) => v(SIPAccounts[i]["Type"]));
                errors = errors.filter((error) => error !== "");
                if (errors) {
                    sipErrors[i].errors["Type"] = errors;
                }
            }
        }

        setErrors(sipErrors);
    }, [SIPAccounts]);

    useEffect(() => {
        getSIPAccounts();
    }, []);

    const getSIPAccounts = async () => {
        setIsLoading(true);
        try {
            const response = await API.graphql(
                graphqlOperation(getSIPAccountStormByDeviceIDCustom, {
                    device_id: deviceID,
                })
            );
            if (response.data.getSIPAccountStormByDeviceID.statusCode !== 200) {
                throw new Error(response.data.getSIPAccountStormByDeviceID.body);
            }
            const sipAccounts = JSON.parse(response.data.getSIPAccountStormByDeviceID.body);
            if (sipAccounts.length > 0) {
                const sipAccountsModels = sipAccounts.map((sipAccount, index) => {
                    return {
                        Type: sipAccount.Type,
                        SIPOwner: sipAccount.SIPOwner,
                        PrimaryAddress: sipAccount.PrimaryAddress || "",
                        SecondaryAddress: sipAccount.SecondaryAddress || "",
                        PbxUsername: sipAccount.PbxUsername || "",
                        PbxPassword: sipAccount.PbxPassword || "",
                        PbxExtension: sipAccount.PbxExtension || "",
                        Name: sipAccount.Name || "",
                        Port: sipAccount.Port || "",
                        id: sipAccount.id,
                    };
                });
                setSIPAccounts(sipAccountsModels);
                //creates a deep copy to store the original values
                setOriginalSIPAccounts(JSON.parse(JSON.stringify(sipAccountsModels)));
            }
        } catch (error) {
            setErrorMessage("Error fetching SIP accounts.");
            setErrorModalOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    function handleAddSipAccount() {
        setSIPAccounts([...SIPAccounts, {
            Type: SIPACCOUNT_TYPE_PUBLIC,
            SIPOwner: SIPOWNER_CUSTOM,
            PrimaryAddress: "",
            SecondaryAddress: "",
            PbxUsername: "",
            PbxPassword: "",
            PbxExtension: "",
            Name: "",
            Port: "",
            id: SIPAccounts.length + 1
        }]);
        setIsUnsaved(true);
    }

    function handleRemoveSipAccount(id) {
        setSIPAccounts(SIPAccounts.filter((sipAccount) => sipAccount.id !== id));
        setIsUnsaved(true);
    }

    async function handleSubmit(e) {
        e.preventDefault();
        SIPAccounts.forEach((sipAccount) => {
            if (sipAccount.SIPOwner === SIPOWNER_VIDATECH) {
                delete sipAccount.SecondaryAddress;
                delete sipAccount.PbxUsername;
                delete sipAccount.PbxPassword;
                delete sipAccount.PbxExtension;
                delete sipAccount.Port;
                if (sipAccount.Type === SIPACCOUNT_TYPE_PUBLIC || !sipAccount.PrimaryAddress) {
                    delete sipAccount.PrimaryAddress;
                }
            }
        });
        SIPAccounts.forEach(sipAccount => {
            if (sipAccount.Port === "") {
                delete sipAccount.Port;
            }
        });
        if (validateForm()) {
            setIsLoading(true);
            try {
                const response = await API.graphql(
                    graphqlOperation(manageSipAccountStormCustom, {
                        input: {
                            DeviceID: deviceID,
                            SIPAccount: SIPAccounts,
                        }
                    }
                    )
                );
                if (response.data.manageSipAccountStorm.statusCode !== 200) {
                    throw new Error(response.data.manageSipAccountStorm.body);
                }
                setIsUnsaved(false);
            } catch (error) {
                setErrorMessage("Error updating SIP accounts");
                setErrorModalOpen(true);
            } finally {
                setIsLoading(false);
                getSIPAccounts();
            }
        }
    }

    const validateForm = () => {
        let errorsBeforeSubmit = [];
        let isValid = true;
        const sipErrors = [];
        SIPAccounts.forEach((sipAccount) => {
            sipErrors.push({sipAccountId: sipAccount.id, errors: []});
        });

        for (const validator in validators) {
            for (let i = 0; i < SIPAccounts.length; i++) {
                if (SIPAccounts[i].SIPOwner === SIPOWNER_CUSTOM || validator === "Name" || (validator === "Type" && SIPAccounts[i].SIPOwner === SIPOWNER_VIDATECH)) {
                    if (validators[validator].length > 0) {
                        let errors = validators[validator].map((v) => v(SIPAccounts[i][validator]));
                        errors = errors.filter((error) => error !== "");
                        if (errors) {
                            sipErrors[i].errors[validator] = errors;
                            errorsBeforeSubmit.push(errors);
                        }
                    }
                }
            }
        }

        errorsBeforeSubmit.forEach((errors) => {
            if (errors.length > 0) {
                isValid = false;
            }
        });

        SIPAccounts.forEach((sipAccount, index) => {
            const originalSipAccount = originalSIPAccounts.find((originalSipAccount) => originalSipAccount.id === sipAccount.id);
            if (originalSipAccount) {
                if(originalSipAccount.SIPOwner === SIPOWNER_VIDATECH && SIPAccounts[index].SIPOwner === SIPOWNER_VIDATECH && SIPAccounts[index].Type === originalSipAccount.Type) {
                    const isDifferent = Object.keys(sipAccount).some((key) => (key !== "Name" && key !== "PrimaryAddress") && sipAccount[key] !== originalSipAccount[key]);
                    if (isDifferent) {
                        isValid = false;
                    }
                }
            }
        });

        setErrors(sipErrors);
        return isValid;
    };

    function handleSipAccountChanged(updatedSipAccount) {
        //replace original values for Vidatech private
        const originalSipAccount = originalSIPAccounts.find((originalSipAccount) => originalSipAccount.id === updatedSipAccount.id);
        if (originalSipAccount && originalSipAccount.SIPOwner === SIPOWNER_VIDATECH && updatedSipAccount.SIPOwner === SIPOWNER_VIDATECH && updatedSipAccount.Type === SIPACCOUNT_TYPE_PRIVATE) {
            updatedSipAccount.SecondaryAddress = originalSipAccount.SecondaryAddress;
            updatedSipAccount.PbxUsername = originalSipAccount.PbxUsername;
            updatedSipAccount.PbxPassword = originalSipAccount.PbxPassword;
            updatedSipAccount.PbxExtension = originalSipAccount.PbxExtension;
            updatedSipAccount.Port = originalSipAccount.Port;
        }
        setSIPAccounts(SIPAccounts.map((sipAccount) => {
            return sipAccount.id === updatedSipAccount.id ? updatedSipAccount : sipAccount;
        }));
        setIsUnsaved(true);
    }

    function handleClose() {
        if (isUnsaved) {
            setConfirmModalOpen(true);
        } else {
            onClose();
        }
    }

    return (
        <Modal
            headerText={"EDIT VOIP LINE"}
            isOpen={true}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit}>
                <div id='sip-form-container'>
                    <div id='sip-form-header'>
                        <CreateButton disabled={SIPAccounts.length >= 2} text="Add a SIP account" onClick={handleAddSipAccount} />
                    </div>
                    <div id="sip-card-container">
                        {
                            isLoading
                                ? <Loader />
                                : SIPAccounts.length === 0
                                    ? <p className="no-sip-accounts">No SIP accounts found for this device</p>
                                    : SIPAccounts.map((sipAccount) => {
                                        return (
                                            <FormProvider
                                                key={sipAccount.id}
                                                initialValues={sipAccount}
                                            >
                                                <SIPCard
                                                    onUpdate={handleSipAccountChanged}
                                                    onRemoveSipAccount={handleRemoveSipAccount}
                                                    validators={validators}
                                                    SIPerrors={SIPErrors.find((error) => error.sipAccountId === sipAccount.id)?.errors || {}}
                                                />
                                            </FormProvider>
                                        );
                                    })
                        }
                    </div>
                    <Button disabled={!isUnsaved} text="Save" type="submit" />
                </div>
            </form>
            <div className="error-modal">
                <Modal
                    isOpen={errorModalOpen}
                    onClose={() => setErrorModalOpen(false)}
                >
                    <>
                        <img
                            src={require("../../img/icon-material-warning@1x.png")}
                            alt="warning sign"
                        />
                        <p>{errorMessage}</p>
                    </>
                </Modal>
            </div>
            <ConfirmModal isOpen={confirmModalOpen} onConfirm={onClose} onCancel={() => setConfirmModalOpen(false)} />
        </Modal>
    );
}

function SIPCard({ onRemoveSipAccount, onUpdate, validators, SIPerrors }) {
    const { data, registerInput, errors } = useContext(FormContextGlobal);

    useEffect(() => {
        if (SIPerrors && Object.keys(SIPerrors).length > 0){
            Object.keys(SIPerrors).forEach((error) => {
                registerInput(error, validators[error])(data[error])
            });
        }
    }, [SIPerrors])

    function handleChange(event) {
        let { name, value } = event.target;
        const shouldClearFields =
            (name === "Type" && data["SIPOwner"] === SIPOWNER_VIDATECH)
            || (name === "SIPOwner" && data["Type"] === SIPACCOUNT_TYPE_PRIVATE);

        if (shouldClearFields) {
            //remove errors
            Object.keys(errors).forEach((error) => {
                errors[error] = [];
            });
            //clear fields
            data["SecondaryAddress"] = "";
            data["PbxUsername"] = "";
            data["PbxPassword"] = "";
            data["PbxExtension"] = "";
            data["Port"] = "";
        }

        if (name === "SIPOwner") {
            data[name] === SIPOWNER_VIDATECH ? value = SIPOWNER_CUSTOM : value = SIPOWNER_VIDATECH;

            const updatedSipAccount = {
                ...data,
                ["SIPOwner"]: value,
                ["PrimaryAddress"]: data["PrimaryAddress"],
                ["SecondaryAddress"]: data["SecondaryAddress"],
                ["PbxUsername"]: data["PbxUsername"],
                ["PbxPassword"]: data["PbxPassword"],
                ["PbxExtension"]: data["PbxExtension"],
                ["Port"]: data["Port"],
            }

            onUpdate(updatedSipAccount);
        } else {
            if (name === "Type") {
                data[name] === SIPACCOUNT_TYPE_PRIVATE ? value = SIPACCOUNT_TYPE_PUBLIC : value = SIPACCOUNT_TYPE_PRIVATE;
            }

            const updatedSipAccount = { ...data, [name]: value };
            onUpdate(updatedSipAccount);
        }
    };

    return (
        <div className="sip-card">
            <FormInputGlobal
                type="text"
                name="Name"
                validators={validators.Name}
                label="Name"
                onChange={handleChange}
            />
            <Toggle
                name="SIPOwner"
                label="Activate the Vidatech VOIP"
                checked={data["SIPOwner"] === SIPOWNER_VIDATECH}
                onChange={handleChange}
            />
            <SelectGlobal
                name="Type"
                label="Select account type"
                value={data["Type"]}
                onChange={handleChange}
                errors={errors["Type"]}
                optionsWithValue={[
                    {
                        value: SIPACCOUNT_TYPE_PRIVATE,
                        text: "Private"
                    },
                    {
                        value: SIPACCOUNT_TYPE_PUBLIC,
                        text: "Public"
                    }
                ]}
            />
            {
                <div className={`sip-fields-container ${data["SIPOwner"] === SIPOWNER_VIDATECH && data["Type"] === SIPACCOUNT_TYPE_PUBLIC ? "hidden" : ""}`}>
                    <FormInputGlobal
                        type="text"
                        name="PrimaryAddress"
                        validators={data["SIPOwner"] === SIPOWNER_VIDATECH && data["Type"] === SIPACCOUNT_TYPE_PRIVATE ? [] : validators.PrimaryAddress}
                        label="Primary address"
                        onChange={handleChange}
                    />
                    <FormInputGlobal
                        type="text"
                        name="SecondaryAddress"
                        label="Secondary address"
                        onChange={handleChange}
                        disabled={data["SIPOwner"] === SIPOWNER_VIDATECH && data["Type"] === SIPACCOUNT_TYPE_PRIVATE}
                    />
                    <FormInputGlobal
                        type="text"
                        name="PbxUsername"
                        validators={validators.PbxUsername}
                        label="Pbx username"
                        onChange={handleChange}
                        disabled={data["SIPOwner"] === SIPOWNER_VIDATECH && data["Type"] === SIPACCOUNT_TYPE_PRIVATE}
                    />
                    <FormInputGlobal
                        type={"password"}
                        name="PbxPassword"
                        label="Pbx password"
                        validators={validators.PbxPassword}
                        onChange={handleChange}
                        disabled={data["SIPOwner"] === SIPOWNER_VIDATECH && data["Type"] === SIPACCOUNT_TYPE_PRIVATE}
                    />
                    <FormInputGlobal
                        type="text"
                        name="PbxExtension"
                        validators={validators.PbxExtension}
                        label="Pbx extension"
                        onChange={handleChange}
                        disabled={data["SIPOwner"] === SIPOWNER_VIDATECH && data["Type"] === SIPACCOUNT_TYPE_PRIVATE}
                    />
                    <FormInputGlobal
                        type="text"
                        name="Port"
                        validators={validators.Port}
                        label="Port"
                        onChange={handleChange}
                        disabled={data["SIPOwner"] === SIPOWNER_VIDATECH && data["Type"] === SIPACCOUNT_TYPE_PRIVATE}
                    />
                </div>
            }
            <div className="sip-card-buttons">
                <button className="delete-sip-button" type="button" onClick={() => onRemoveSipAccount(data["id"])}>
                    <img src={require("../../img/picto-delete.png")} alt='delete' />
                </button>
            </div>
        </div>
    );
}