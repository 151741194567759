import "@aws-amplify/ui-react/styles.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import awsExports from "../aws-exports";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import { Auth } from "@aws-amplify/auth";
import "./Login.css";
import { useLocation } from "react-router-dom";
import { CONTRACTOR_ID_FIELD, OWNER_ID_FIELD } from "../constants/Fields";
import { Amplify, Hub } from "aws-amplify";

export default function Login({ children }) {
  const { route, user } = useAuthenticator((context) => [context.route]);
  const [isUserActive, setIsUserActive] = useState(false);
  const navigate = useNavigate();
  let from = useLocation().pathname;
  let refreshSessionTimeout = null;

  async function refreshSessionAndGetAccessTokenExpiration()  {
    try  {
      const refreshedUser = await Auth.currentAuthenticatedUser()
      return refreshedUser.signInUserSession.accessToken.payload.exp
    } catch (error)  {
      console.error("Error refreshing session:", error);
    }
  }

  async function refreshSessionAndStartTimeout() {
    try {
      const accessTokenExpiration  = await refreshSessionAndGetAccessTokenExpiration()
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const timeBeforeExpiration = (accessTokenExpiration - currentTime + 1) * 1000;

      if (refreshSessionTimeout) clearTimeout(refreshSessionTimeout);

      refreshSessionTimeout = setTimeout(async () => {
        if (isUserActive) {
          await refreshSessionAndStartTimeout();
        }
      }, timeBeforeExpiration);
    } catch (error) {
      console.error("Error refreshing session:", error);
    }
  }

  useEffect(() => {
    async function handleUserSessionExpiration() {
      try {
        // Disconnect the user when the website is first accessed
        if (!isUserActive) {
          const authTime = user.signInUserSession.accessToken.payload.auth_time;
          const exp = user.signInUserSession.accessToken.payload.exp;
          const iat = user.signInUserSession.accessToken.payload.iat;
          const currentTime = Math.floor(new Date().getTime() / 1000);

          let accessTokenExpiration = authTime + (exp - iat);

          if (currentTime >= accessTokenExpiration) {
            await Auth.signOut();
            return;
          }
        } else {
          await refreshSessionAndStartTimeout();
        }
      } catch (error) {
        console.error("Error checking session expiration");
      } finally {
        setIsUserActive(true);
      }
    }

    async function checkAndRedirect() {
      if (route === "authenticated") {
        await handleUserSessionExpiration();
        const hasRedirected = sessionStorage.getItem("postLoginRedirect");
        try {
          const currentUser = await Auth.currentAuthenticatedUser({bypassCache: true});
          const {
            idToken: { payload },
          } = currentUser.signInUserSession;

          let isContractor =
            currentUser.attributes?.[CONTRACTOR_ID_FIELD] !== undefined;
          let isOwner = currentUser.attributes?.[OWNER_ID_FIELD] !== undefined;

          const isRedirected = (!isContractor && !isOwner) || from === "/";

          if (!isContractor && !isOwner) {
            from = "/contractor";
          } else if (from === "/") {
            from = "/buildings";
          }

          if (!hasRedirected) {
            if (payload && payload.redirectTo) {
              if (payload.redirectTo === "/contractor") {
                sessionStorage.setItem("postLoginRedirect", "false"); // Set flag for contractor
              } else {
                sessionStorage.setItem("postLoginRedirect", "true"); // Set flag for other routes
              }
              navigate(payload.redirectTo);
            } else if (isRedirected) {
              navigate(from); // default route if no redirectTo is found
            }
          } else if (isRedirected) {
            navigate(from);
          }
        } catch (error) {
          console.error("Error fetching user: ", error);
          navigate("/");
          window.location.reload();
        }
      }
    }

    checkAndRedirect();
    // Cleanup on component unmount
    return () => {
      if (refreshSessionTimeout) clearTimeout(refreshSessionTimeout);
    };
  }, [route, navigate]);

  return isUserActive ? children : null;
}
