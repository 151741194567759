import React, { useState, useEffect } from "react";
import Layout from "../../containers/Layout/Layout";
import {
  listContractorsStormCustom,
  listOwnersStormCustom,
  listPageBuildingsByUser,
  listBuildingMikrotikClientsStormCustom,
} from "../../graphql/queries";
import {
  decryptMikrotikPassword,
  takeContractorStorm,
  transferBuildingOwnershipStorm,
  createTeamsAutomationStorm
} from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import "./css/VidatechAdmin.css";
import Button from "../Buttons/Button";
import { Auth } from "@aws-amplify/auth";
import DetailsButton from "../../components/Buttons/DetailsButton";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { CustomAttributes } from "../../constants/CustomAttributes";
import Modal from "../../components/Modal/Modal";
import ItemsTable from "../Table/ItemsTable";
import { BUILDING_WITH_IP_HEADERS } from "../../constants/TableHeaders";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { StatusCodes } from "../../constants/StatusCodes";

const VidatechAdmin = () => {
  const [owners, setOwners] = useState([]);
  const [ownerChecked, setOwnerChecked] = useState(false);
  const [contractorChecked, setContractorChecked] = useState(false);
  const [contractors, setContractors] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTransferDetails, setShowTransferDetails] = useState(false);
  const [buildings, setBuildings] = useState(null);
  const [buildingsWithIp, setBuildingsWithIp] = useState(null);
  const [selectedBuildingId, setSelectedBuildingId] = useState(null);
  const { user } = useAuthenticator((context) => [context.user]);
  const userId = user.attributes[CustomAttributes.CONTRACTOR] || user.attributes[CustomAttributes.OWNER]
  const [currentUserID, setCurrentUserID] = useState(userId);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("An error occured");
  const [searchQuery, setSearchQuery] = useState("");
  const [isOwnerLoading, setIsOwnerLoading] = useState(false);
  const [isBuildingLoading, setIsBuildingLoading] = useState(false);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const [commandNumber, setCommandNumber] = useState("");

  const filteredBuildings = buildingsWithIp
    ? buildingsWithIp.filter((building) =>
        Object.values(building).some((value) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    : [];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    getBuildingsWithIp();
  }, []);

  useEffect(() => {
    if (user) {
      if (userId !== currentUserID) {
        window.location.reload();
      }
      fetchUsers().then((users) => setCurrentSelectedUser(users.find((user) => user.id === currentUserID)));
      if (showTransferDetails) {
        setSelectedBuildingId(null);
        getBuildings();
      }
    }
  }, [currentUserID]);

  async function fetchUsers() {
    const owners = await getOwners();
    if (owners) {
      setOwners(owners);
    }
    const contractors = await getContractors();
    if (contractors) {
      setContractors(contractors);
    }
    
    owners?.forEach((owner) => {
      owner.Name = owner.BusinessName;
    });
    return owners.concat(contractors);
  }

  async function getBuildingsWithIp() {
    setIsLoading(true);
    try {
      const values = await API.graphql(
        graphqlOperation(listBuildingMikrotikClientsStormCustom)
      );
      const buildingModels =
        values.data.listBuildingMikrotikClientsStorm.items.map(
          (item) => {
            return {
              id: item.id,
              Name: item.Name,
              Address: `${item.Address.Street1}, ${item.Address.City}, ${item.Address.Country}`,
              Phone: item.Phone,
              IpAddress: item.SSHNebulaConnectionInfo?.IpAddress || "",
              DstAddress: item.MikrotikClients?.DstAddress || "",
              Password: item.MikrotikClients?.Password || "",
              RemoteAddress: item.MikrotikClients?.RemoteAddress || "",
              Username: item.MikrotikClients?.Username || "",
              PasswordDecrypted: false
            };
          }
        );
      setBuildingsWithIp(
        buildingModels.sort((a, b) =>
          a.Name.localeCompare(b.Name, undefined, {
            numeric: true,
            sensitivity: "base",
          })
        )
      );
    } catch (error) {
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function getOwners() {
    setIsOwnerLoading(true);
    try {
      const values = await API.graphql(graphqlOperation(listOwnersStormCustom));
      return values.data.listOwnersStorm.items
    } catch (error) {
      setErrorMessage("An error occurred while trying to get owners.");
      setErrorModalOpen(true);
    } finally {
      setIsOwnerLoading(false);
    }
  }

  async function getContractors() {
    setIsLoading(true);
    try {
      const values = await API.graphql(
        graphqlOperation(listContractorsStormCustom)
      );
      return values.data.listContractorsStorm.items
    } catch (error) {
      setErrorMessage("An error occurred while trying to get contractors.");
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleCheckboxChange(event) {
    if (event.target.checked) {
      if (event.target.name === "owner") {
        setOwnerChecked(true);
        setContractorChecked(false);
      } else {
        setContractorChecked(true);
        setOwnerChecked(false);
      }
    } else {
      if (event.target.name === "owner") {
        setOwnerChecked(false);
      } else {
        setContractorChecked(false);
      }
    }
    setSelectedUserId(null);
  }

  function handleTransferDetailsToggle() {
    setShowTransferDetails(!showTransferDetails);
    if (!showTransferDetails && !buildings) {
      getBuildings();
    }
  }

  async function takeControl(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const input = contractorChecked
        ? { contractorsID: selectedUserId }
        : { ownersID: selectedUserId };

      const result = await API.graphql(
        graphqlOperation(takeContractorStorm, {
          input,
        })
      );

      if (result.data.takeContractorStorm.statusCode !== 200) {
        throw new Error("An error occurred while trying to change session.");
      }

      setSelectedUserId(null);
      setSelectedBuildingId(null);
      setBuildings(null);
      //reset token
      const newUser = await Auth.currentAuthenticatedUser({ bypassCache: true })
      const newUserId = newUser.attributes[CustomAttributes.CONTRACTOR] ||
        newUser.attributes[CustomAttributes.OWNER];
      setCurrentUserID(newUserId);
    } catch (e) {
      setErrorMessage("An error occurred while trying to change session.");
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function getBuildings() {
    setIsBuildingLoading(true);
    try {
      const values = await API.graphql(
        graphqlOperation(listPageBuildingsByUser)
      );
      setBuildings(values.data.listBuildingsByUser.items);
    } catch (error) {
      setErrorMessage("An error occurred while trying to get buildings.");
      setErrorModalOpen(true);
    } finally {
      setIsBuildingLoading(false);
    }
  }

  async function transferOwnership(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      let isSelectedUserOwner = owners.find(
        (owner) => owner.id === selectedUserId
      );
      const input = {
        buildingID: selectedBuildingId,
        ownersID: isSelectedUserOwner ? selectedUserId : null,
        contractorsID: isSelectedUserOwner ? null : selectedUserId,
      };
      const result = await API.graphql(
        graphqlOperation(transferBuildingOwnershipStorm, {
          input,
        })
      );
      if (result.data.transferBuildingOwnershipStorm.statusCode !== 200) {
        throw new Error(
          "An error occurred while trying to transfer ownership."
        );
      }
      setSelectedBuildingId(null);
      setBuildings(null);
    } catch (error) {
      setErrorMessage("An error occurred while trying to transfer ownership.");
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
      getBuildings();
    }
  }

  const handleSortBy = (header, sortOrder) => {
    const sortedBuildings = [...buildingsWithIp];
    if (sortOrder === "asc") {
      sortedBuildings.sort((a, b) =>
        a[BUILDING_WITH_IP_HEADERS[header]].localeCompare(
          b[BUILDING_WITH_IP_HEADERS[header]],
          undefined,
          { numeric: true, sensitivity: "base" }
        )
      );
    } else {
      sortedBuildings.sort((a, b) =>
        b[BUILDING_WITH_IP_HEADERS[header]].localeCompare(
          a[BUILDING_WITH_IP_HEADERS[header]],
          undefined,
          { numeric: true, sensitivity: "base" }
        )
      );
    }
    setBuildingsWithIp(sortedBuildings);
  };

  async function handlePasswordIconClicked(item) {
    try {
      const response = await API.graphql(
        graphqlOperation(decryptMikrotikPassword, { Password: item.Password })
      );
      if (response.data?.decryptMikrotikPassword?.statusCode !== StatusCodes.OK) {
        throw new Error(response.data?.decryptMikrotikPassword?.body)
      }
      setBuildingsWithIp(
        buildingsWithIp.map(building => {
          if (building.id === item.id){
            building.Password = response.data?.decryptMikrotikPassword?.body;
            building.PasswordDecrypted = true;
          }
          return building;
        }));
    } catch (error) {
      setErrorMessage(`Error decrypting password: ${error.message}`);
      setErrorModalOpen(true);
    }
  }

  async function handleSubmitAutomation() {
    try {
      if (commandNumber) {
        const response = await API.graphql(
          graphqlOperation(createTeamsAutomationStorm, { id: commandNumber })
        );
        if (response.data?.createTeamsAutomationStorm?.statusCode !== StatusCodes.OK) {
          throw new Error(response.data?.createTeamsAutomationStorm?.body)
        }
        setCommandNumber("")
      }
    } catch (error) {
      setErrorMessage(`Error submitting automation: ${error.message}`);
      setErrorModalOpen(true);
    }
  }

  return (
    <Layout
      headerText="VIDATECH ADMIN DASHBOARD"
      showSidebar={true}
      showFooter={false}
    >
      <div id="vidatech-admin-content">
        <div>
          <form id="take-control-form" onSubmit={takeControl}>
            <div className="current-user">
              <h1>Current controlled user:</h1>
              <p>{ currentSelectedUser && `${currentSelectedUser.Name} - ${currentSelectedUser.Email}` }</p>
            </div>
            <h2>Select a user</h2>
            <div className="user-selection">
              <div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    id="owner"
                    checked={ownerChecked}
                    className={ownerChecked ? "checked" : null}
                    name="owner"
                    onChange={handleCheckboxChange}
                  />
                </div>
                <label className="titreInput" htmlFor="owner">
                  Owner
                </label>
              </div>
              <UserSelect
                name="owners"
                userChecked={ownerChecked}
                users={owners.filter((owner) => owner.id !== currentUserID)}
                isLoading={isOwnerLoading}
                handleUserSelected={(e) => setSelectedUserId(e.target.value)}
              />
            </div>
            <div className="user-selection">
              <div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    id="contractor"
                    name="contractor"
                    className={contractorChecked ? "checked" : ""}
                    checked={contractorChecked}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <label className="titreInput" htmlFor="contractor">
                  Contractor
                </label>
              </div>
              <UserSelect
                name="contractors"
                userChecked={contractorChecked}
                users={contractors.filter(
                  (contractor) => contractor.id !== currentUserID
                )}
                isLoading={isLoading}
                handleUserSelected={(e) => setSelectedUserId(e.target.value)}
              />
            </div>
            <Button
              disabled={!selectedUserId || isLoading}
              type={"submit"}
              text={"Take control"}
            />
          </form>
          <div id="transfer-ownership">
            <div id="transfer-header">
              <DetailsButton
                opened={false}
                onToggle={handleTransferDetailsToggle}
              />
              <h2>Transfer ownership</h2>
            </div>
            {showTransferDetails && (
              <form id="transfer-ownership-form" onSubmit={transferOwnership}>
                <div>
                  <label htmlFor="buildings">Building</label>
                  <div>
                    <div
                      className={`user-selection-spinner ${
                        isBuildingLoading && !buildings ? "visible" : ""
                      }`}
                    />
                    {
                      <select
                        name="buildings"
                        id="buildings"
                        value={selectedBuildingId || ""}
                        disabled={
                          !buildings ||
                          (buildings && buildings.length === 0) ||
                          isBuildingLoading
                        }
                        onChange={(e) => setSelectedBuildingId(e.target.value)}
                        className={`select-control ${isBuildingLoading ? "disabled" : ""}`}
                      >
                        {!buildings ? (
                          <option value="">Please select a building</option>
                        ) : buildings.length === 0 ? (
                          <option value="">No buildings available</option>
                        ) : (
                          <option value="">Please select a building</option>
                        )}
                        {buildings &&
                          buildings.map((building) => (
                            <option key={building.id} value={building.id}>
                              {building.Name}
                            </option>
                          ))}
                      </select>
                    }
                  </div>
                </div>
                <Button
                  disabled={!selectedBuildingId || !selectedUserId || isBuildingLoading}
                  type={"submit"}
                  text={"Transfer ownership"}
                />
              </form>
            )}
          </div>
        </div>
        <div id="automation">
          <h1>Automation</h1>
          <form id="automation-form">
            <label className="titreInput">
              Command number
              <input value={commandNumber} onChange={(e) => setCommandNumber(e.target.value)} className="form-control" type="text" />
            </label>
            <Button disabled={commandNumber.length === 0} onClick={handleSubmitAutomation} text="Submit" />
          </form>
        </div>
      </div>
      <div id="vidatech-admin-buildings">
        {buildingsWithIp ? (
          <>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-input"
            />
            <ItemsTable
              headers={BUILDING_WITH_IP_HEADERS}
              searchQuery={searchQuery}
              onSortBy={handleSortBy}
              lastItemSortable={true}
            >
              {filteredBuildings.map((item) => (
                <BuildingItemTableRow key={item.id} item={item} onPasswordClicked={handlePasswordIconClicked} />
              ))}
            </ItemsTable>
            {filteredBuildings.length === 0 && (
              <p className="no-result">No buildings found</p>
            )}
          </>
        ): SkeletonRow({ columnCount: 6 })}
      </div>
      <div className="error-modal">
        <Modal isOpen={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
          <>
            <img
              src={require("../../img/icon-material-warning@1x.png")}
              alt="warning sign"
            />
            <p>{errorMessage}</p>
          </>
        </Modal>
      </div>
    </Layout>
  );
};

const UserSelect = ({
  name,
  userChecked,
  users,
  isLoading,
  handleUserSelected,
}) => (
  <div className="user-select">
    <div
      className={`user-selection-spinner ${
        isLoading && userChecked ? "visible" : ""
      }`}
    />
    <select
      name={name}
      id={name}
      disabled={!userChecked || users.length === 0}
      onChange={handleUserSelected}
      className={`select-control ${
        !userChecked || (userChecked && users.length === 0) ? "disabled" : ""
      }`}
    >
      {userChecked && !isLoading && users.length === 0 ? (
        <option value="">No users available</option>
      ) : (
        <option value="">Please select a user</option>
      )}
      {userChecked &&
        users.map((user) => (
          <option key={user.id} value={user.id}>
            {name === "owners" ? user.BusinessName : user.Name} - {user.Email}
          </option>
        ))}
    </select>
  </div>
);

const SkeletonRow = ({ columnCount }) => {
    return (
        <div className="skeleton-container">
            <div className="skeleton-row">
                {Array.from({ length: columnCount }).map((_, index) => (
                    <div key={index} className="skeleton-cell">
                        <div className="skeleton" />
                    </div>
                ))}
            </div>
            <div className="skeleton-row">
                <div className="skeleton-cell">
                    <div className="skeleton"></div>
                </div>
            </div>
        </div>
    );
};

const BuildingItemTableRow = ({ item, onPasswordClicked }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handlePasswordIconClicked() {
    if (!showPassword) {
      if (!item.PasswordDecrypted) {
        setIsLoading(true);
        await onPasswordClicked(item);
        setIsLoading(false);
      }
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  }

  return (
    <tr
      id={item.id}
      className="building-ip-row"
      onClick={() => {}}
      data-testid={`building-row-${item.Name}`}
    >
      {Object.keys(item).map((key) => (
        key !== "id" && key!== "PasswordDecrypted" &&
        <td
          key={key}
          className={`cell-container ${["Name", "Address", "Username", "Password"].includes(key) ? "large" : "medium"}`}
        >
          <div>
            <div title={item[key]} className="table-cell">
              {(key === "Password" && item[key])
                ? <div className="microtik-password">
                    <p className={`password ${isLoading ? "loading" : ""}`}>{showPassword
                          ? item.Password
                          : (
                            <>
                              <span>●</span>
                              <span>●</span>
                              <span>●</span>
                              <span>●</span>
                            </>
                          )}</p>
                    <i onClick={handlePasswordIconClicked} className="password-icon">
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </i>
                  </div>
                : item[key]}
            </div>
            <span className="separator"></span>
          </div>
        </td>
      ))}
    </tr>
  )
}

export default VidatechAdmin;
