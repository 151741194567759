import React from 'react';
import './css/SidebarHeader.css'
import backArrowImg from '../../img/back-arrow.png'
import { useNavigate } from 'react-router-dom';
import { useSharedData } from "../../context/SharedProvider";

const SidebarHeader = (props) => {
    const navigate = useNavigate();
    const { buildingInfo } = useSharedData();

    function handleNavigateBack() {
        navigate("/buildings");
    }

    return (
        <div id='sidebar-header'>
            <div id='back-button' onClick={handleNavigateBack}>
                <img src={backArrowImg} alt='Back button' />
            </div>
            <div id='building-info'>
                {
                    buildingInfo
                        ? <>
                            <h1 title={buildingInfo.Name} data-testid='sidebar-building-name'>{buildingInfo.Name}</h1>
                            <p title={buildingInfo.Address}>{buildingInfo.Address}</p>
                        </>
                        : <>
                            <div className='skeleton'></div>
                            <div className='skeleton'></div>
                        </>
                }
            </div>
            <button onClick={props.onBuildingOptionsClicked} id='download-config-button'>
                <img src={require('../../img/arrow-down-small.png')} alt='Download icon' />
            </button>
        </div>
    );
};

export default SidebarHeader;
