import React from "react";
import "./css/Tooltip.css";
import pictoWarning from "../../../img/picto-warning.svg";
import infoTooltip from "../../../img/info-tooltip.svg";

const Tooltip = ({ children, text, warning, messages }) => (
    <div className="title-tooltip"> 
        {children}
        <div className="tooltip-container">
            <img src={warning ? pictoWarning : infoTooltip} className="tooltip-icon" />
            {text && <span className="tooltiptext">{text}</span>}
            {messages && <span className="tooltiptext tooltipmessages">
                {text && <p style={{fontWeight: "bold"}}>{text}</p>}
                {messages.map((message, index) => (
                    <div key={index}>• {message}</div>
                ))}
            </span>}
        </div>
    </div>
);

export default Tooltip;