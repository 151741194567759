import Button from "../../Buttons/Button";
import Modal from "../../Modal/Modal";
import "./ConfirmModal.css";

const ConfirmModal = ({ isOpen, onConfirm, onCancel} ) => {
    return (
        isOpen && 
            <div className="confirm-modal">
                <Modal
                    isOpen={true}
                    onClose={onCancel}
                >
                    <>
                        <img
                            src={require("../../../img/icon-material-warning@1x.png")}
                            alt="modal"
                        />
                        <p>Are you sure you want to close without saving?</p>
                        <div className="confirm-modal-buttons">
                            <Button text={"Yes"} onClick={onConfirm}>Yes</Button>
                            <Button text={"No"} onClick={onCancel}>No</Button>
                        </div>
                    </>
                </Modal>
            </div>
    )
};

export default ConfirmModal;