import React, { useEffect, useState, useRef } from "react";
import "./css/CreateButton.css";
import createButtonImage from "../../img/create-button.png";
import Button from "./Button";

/**
 * Renders a create button component.
 *
 * @param {string} props.text - The text to display on the button.
 * @param {function} props.onClick - The function to be called when the button is clicked.
 * @returns {JSX.Element} The create button component.
 */
const CreateButton = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef(null);

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClick() {
    if (!disabled) {
      if (props.options) {
        setShowOptions(!showOptions);
      }
      if (props.onClick) {
        props.onClick();
      }
    }
  }

  function handleOptionClicked(e, option) {
    e.stopPropagation();
    props.onClick(option.value);
    setShowOptions(!showOptions);
  }

  return (
    <div
      ref={optionsRef}
      style={props.style}
      className={`create-button${disabled ? " disabled" : ""}${props.className ? ` ${props.className}` : ""}`}
      onClick={handleClick}
    >
      <Button dataTestid={"create-button"} text={props.text} disabled={disabled} />
      <div className="plus-sign">
        <img src={props.buttonImgSrc ? props.buttonImgSrc : createButtonImage} alt="plus sign"></img>
      </div>
      {props.options && showOptions && (
        <div className="content-select">
          {props.options.map((option) =>
            <div data-testid={`content-option-${option.value}`} key={option.value} className="content-option" onClick={(e) => handleOptionClicked(e, option)}>{option.label}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CreateButton;
