import React, { useEffect, useState, useRef } from "react";
import { FormProvider } from "../Form/FormProvider";
import { fieldFormatValidator, MAX_VALUE, requiredValidator, VALIDATOR_FORMATS } from '../../constants/RequiredValidator'
import InputGlobal from '../Form/InputGlobal';
import CalleeCardForm from "./CalleeCardForm";
import './css/CalleeForm.css';
import Sortable from 'sortablejs';
import Button from "../Buttons/Button";
import CreateButton from "../Buttons/CreateButton";

function CalleeForm(props) {
  const [validators] = useState({
    Number: [requiredValidator],
    LinkEmail: [(e) => fieldFormatValidator(e, VALIDATOR_FORMATS.EMAIL)],
    LinkNumber: [(e) => fieldFormatValidator(e, VALIDATOR_FORMATS.NORTH_AMERICAN_PHONE)],
    Time: [requiredValidator, (e) => fieldFormatValidator(e, VALIDATOR_FORMATS.RING_TIME)],
  });
  const sortableCards = useRef(null);

  useEffect(() => {
    if (props.calleesInfo) {
      props.calleesInfo.Callees.forEach(callee => {
        callee.LinkEmail = callee.LinkEmail || '';
        callee.LinkNumber = callee.LinkNumber || '';
      });
      let sortableInstance = null;
      if (sortableCards.current) {
        sortableInstance = Sortable.create(sortableCards.current, {
          animation: 350,
          ghostClass: 'blue-background-class',
          draggable: '.callee-card-form',
          handle: '.drag-icon',
          onEnd: function (evt) {
            if (evt.oldIndex !== evt.newIndex) {
              let updatedCallees = props.calleesInfo.Callees;
              const [removed] = updatedCallees.splice(evt.oldIndex, 1);
              updatedCallees.splice(evt.newIndex, 0, removed);
              updatedCallees = updatedCallees.map((callee, index) => {
                callee.Priority = (index + 1).toString();
                return callee;
              });
              props.onUpdate({ ...props.calleesInfo, Callees: updatedCallees });
            }
          }
        });
      }
      return () => {
        if (sortableInstance) {
          sortableInstance.destroy();
        }
      };
    }
  }, [props.calleesInfo]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    props.calleesInfo.Callees.forEach((callee) => {
      callee.LinkNumber = callee.LinkNumber?.replace(/\D/g, '');
      if (callee.LinkNumber === "") {
        delete callee.LinkNumber;
      }
      if (callee.LinkEmail === "") {
        delete callee.LinkEmail;
      }
    });
    if (validateForm()) {
      props.onSubmit(props.calleesInfo);
    }
  };

  const validateForm = () => {
    let errorsBeforeSubmit = [];

    for (const validator in validators) {
      if(validator !== 'Time') {
        for(let i = 0; i < props.calleesInfo.Callees.length; i++) {
          if (validators[validator].length > 0) {
            let errors = validators[validator].map((v) => v(props.calleesInfo.Callees[i][validator]));
            errors = errors.filter((error) => error !== "");
            if (errors) {
              errorsBeforeSubmit.push(errors);
            }
          }
        }
      } else {
        let error = validators[validator].map((v) => v(props.calleesInfo.RingGroup[validator]));
        error = error.filter((error) => error !== "");
        if (error) {
          errorsBeforeSubmit.push(error);
        }
      }
    }

    let isValid = true;

    errorsBeforeSubmit.forEach((errors) => {
      if (errors.length > 0) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleRingTimeChanged = (event) => {
    const { name, value } = event.target;
    props.onUpdate({ ...props.calleesInfo, RingGroup: { ...props.calleesInfo.RingGroup, [name]: value } });
  };

  const handleCalleeChanged = (callee) => {
    const updatedCallees = props.calleesInfo.Callees.map((c) => {
      if (c.id === callee.id) {
        return callee;
      }
      return c;
    });
    props.onUpdate({ ...props.calleesInfo, Callees: updatedCallees });
  };

  const handleDelete = (id) => {
    const updatedCallees = props.calleesInfo.Callees.filter((c) => c.id !== id);
    updatedCallees.forEach((c, index) => {
      c.Priority = (index + 1).toString();
    });
    props.onUpdate({ ...props.calleesInfo, Callees: updatedCallees });
  }

  return (
    props.calleesInfo && 
      <FormProvider
        initialValues={props.calleesInfo.RingGroup}
        onSubmit={(event) => handleSubmit(event)}
      >
        <form data-testid="callee-form" className='callee-form' onSubmit={(event) => handleSubmit(event)}>
          <CreateButton text={"Add a callee"} onClick={props.onAddCalleeClicked} />
          <div className="calleeCardForms-container" ref={sortableCards}>
            {props.calleesInfo && props.calleesInfo.Callees.map((callee, index) => (
              <FormProvider
                key={callee.id}
                initialValues={callee}
                onSubmit={handleSubmit}
              >
                <CalleeCardForm
                  key={callee.id}
                  priority={index + 1}
                  callee={callee}
                  onUpdate={handleCalleeChanged}
                  onDelete={handleDelete}
                  validators={validators}
                  sipAccounts={props.sipAccounts}
                />
              </FormProvider>
            ))}
          </div>
          <div className="main-content-footer">
            <div className="settings-title">
              <h1>SETTINGS</h1>
            </div>
            <div>
              <InputGlobal
                type='number'
                name='Time'
                required={true}
                label="Ring time (seconds) :"
                validators={validators.Time}
                max={MAX_VALUE.RING_TIME}
                min={1}
                onChange={handleRingTimeChanged}
              />
              <Button disabled={props.saveButtonDisabled} text="Save" type="submit" />
            </div>
          </div>
        </form>
      </FormProvider>
  );
}

export default CalleeForm;
