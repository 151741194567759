import React from "react";
import { withTranslation } from "react-i18next";
import { useState } from "react";
import Modal from "../Modal/Modal";
import Action from "../Action/Action";
import UserFormProvider from "./UserFormProvider";
import { COGNITO_GROUPS } from "../../constants/DefaultValues";

const UserManagementMenuItem = (props) => {
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const item = props.item;

  const handleUpdate = (data) => {
    props.onUpdate(data);
    setEditModalOpen(false);
  }

  const handleDelete = () => {
    props.onDelete(item.email);
  }

  const actionOptions = [
    {
      text: "Edit",
      img: require("../../img/picto-edit-1.png"),
      onClickOption: () => setEditModalOpen(true)
    }
  ];

  if (item.role !== COGNITO_GROUPS.ADMIN && item.role !== COGNITO_GROUPS.VIDATECH_ADMIN) {
    actionOptions.push(
      {
        text: "Delete",
        img: require("../../img/picto-delete.png"),
        onClickOption: () => setDeleteModalOpen(true)
      }
    );
  }

  return (
      <tr data-testid={`user-row-${item.email}`} id={item.email} key={props.key}>
        <td className="cell-container">
          <div>
            <div className='table-cell'>{item.name}</div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className='table-cell'>{item.email}</div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className='table-cell'>{item.role}</div>
            <span className="separator"></span>
          </div>
        </td>
        <td>
          <Action actionOptions={actionOptions}></Action>
          <div className="user-form-modal">
            <Modal headerText={`EDIT USER - ${item.name}`}
              isOpen={EditModalOpen}
              onClose={() => setEditModalOpen(false)} >
              <UserFormProvider item={item} onSubmit={handleUpdate} />
            </Modal>
          </div>
          <div className="confirm-modal">
            <Modal isOpen={DeleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              buttonText="Delete"
              buttonClick={handleDelete} >
              <>
                <img src={require("../../img/icon-material-warning@1x.png")} alt="warning sign" />
                <p>Are you sure you want to delete this user?</p>
              </>
            </Modal>
          </div>
        </td>
      </tr>
  );
};

export default withTranslation()(UserManagementMenuItem);
