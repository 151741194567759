import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import InputContextForm from "./InputContextForm";
import "./css/FormTextInputFirst.css";
import { FormContextGlobal } from "./FormProvider";
import infoTooltip from "../../img/info-tooltip.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  tooltipText: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.func),
  preventDecimal: PropTypes.bool
};

const InputGlobal = ({
  label,
  style,
  name,
  type,
  placeholder,
  value,
  errors,
  onChange,
  disabled,
  required,
  validators,
  tooltipText,
  preventDecimal,
  ...restProps
}) => {
  const { data, setFieldValue, registerInput } = useContext(FormContextGlobal);
  const validateField = registerInput(name, validators);
  const [showPassword, setShowPassword] = useState(false);
  let hasError = false;

  hasError = errors?.some((e) => e.length > 0);

  const handleChange = (e) => {
    if (type === "tel") {
      e.target.value = formatPhoneNumber(e.target.value);
    }
    //remove emojis
    e.target.value = e.target.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');

    setFieldValue(name, e.target.value);
    onChange && onChange(e);
  };

  const formatPhoneNumber = (number) => {
    let previousValue = data[name];
    if (!previousValue || number.length >= previousValue?.length + 1) {
      let lastCharacter = number[number.length - 1];
      number = number.replace(/\D/g, "");
      if (number.length > 6) {
        number =
          "(" +
          number.substring(0, 3) +
          ") " +
          number.substring(3, 6) +
          "-" +
          number.substring(6, 10);
      } else if (number.length >= 3) {
        number = "(" + number.substring(0, 3) + ") " + number.substring(3, 6);
      } else if (number.length > 0) {
        number = "(" + number.substring(0, 3);
      }
      switch (lastCharacter) {
        case " ":
          number = number + " ";
          break;
        case "-":
          number = number + "-";
          break;
        case "(":
          number = number + "(";
          break;
        default:
          break;
      }
    } else {
      switch (number[number.length - 1]) {
        case "-":
          number = number.slice(0, -1);
          break;
        case "(":
          number = number.slice(0, -1);
          break;
        case " ":
          number = number.slice(0, -2);
          break;
      }
    }

    return number;
  };

  const handleBlur = (e) => {
    validateField(e.target.value);
  };

  const renderErrors = () => {
    if (!hasError) return null;
    return (
      <ul className="error-messages">
        {errors?.map((errMsg, i) => (
          <li key={`${name}-error-${i}`} className="error">
            {errMsg}
          </li>
        ))}
      </ul>
    );
  };

  const klass = classNames(
    "form-group-input",
    { "has-error": hasError },
    { "checkbox-container": type === "checkbox" },
    { "password-input-container": type === "password"}
  );

  const handleDecimal = (e) => {
    if (e.key === "." || e.key === ",") {
      e.preventDefault();
    }
  };

  return (
    <div className={klass}>
      {label && <label className="titreInput">{label}</label>}
      <div style={{display: "flex"}}>
        <input
          style={style}
          name={name}
          type={type === "password" ? (showPassword ? "text" : "password") : type}
          className={type !== "color" ? "form-control" : "color-input"}
          placeholder={placeholder}
          onChange={handleChange}
          value={
            data[name]?.length === 10 && type === "tel"
              ? `(${data[name].slice(0, 3)}) ${data[name].slice(3, 6)}-${data[
                  name
                ].slice(6)}`
              : data[name]
          }
          disabled={disabled}
          required={required}
          onBlur={handleBlur}
          onKeyDown={preventDecimal ? handleDecimal : null}
          {...restProps}
        />
        {
          type === "password" &&
          <i onClick={() => setShowPassword(!showPassword)} className="password-icon">
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
          </i>
        }
      </div>
      {tooltipText && (
        <div className="tooltip-container">
          <img src={infoTooltip} className="tooltip-icon" />
          <span className="tooltiptext">{tooltipText}</span>
        </div>
      )}
      {renderErrors()}
    </div>
  );
};

InputGlobal.propTypes = propTypes;

const FormInputGlobal = InputContextForm(InputGlobal);

export { InputGlobal };
export default FormInputGlobal;
